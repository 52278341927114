import BN from 'bn.js'
import web3 from 'web3'
const numberToBN = require('number-to-bn')

export interface Options {
	decimals: number | null
	commify: boolean | null
	pad: boolean | null
}

const zero = new BN(0)
const negative1 = new BN(-1)

export default class Currency {
	protected val: BN
	protected decimals: number = 9
	protected opts: Options | null

	constructor(val: string | BN | number, opts: Options | unknown) {
		if (typeof val == 'string' && val == '') {
			this.val = new BN(0)
		} else if (typeof val == 'string' || typeof val == 'number') {
			this.val = numberToBN(val)
		} else {
			this.val = val
		}

		this.opts = opts || {
			decimals: 9,
		}

		if (opts) {
			this.decimals = opts.decimals || 9
		}
	}

	percentage(bps: number): string {
		return this.val.mul(numberToBN(bps)).div(numberToBN(100)).toString()
	}

	addPercentage(bps: number): Currency {
		const amt = this.percentage(bps)
		return new Currency(this.val.add(numberToBN(amt)), this.opts)
	}

	add(amt: number): Currency {
		return new Currency(this.val.add(numberToBN(amt)), this.opts)
	}

	mul(amt: number): Currency {
		return new Currency(this.val.mul(numberToBN(amt)), this.opts)
	}

	toDecimal(opts: Options | null): string {
		let num = numberToBN(this.val) // eslint-disable-line
		const negative = num.lt(zero) // eslint-disable-line
		const base = new BN('1' + new Array(this.decimals).fill(0).join(''))
		const baseLength = base.length - 1 || 1
		const options = opts || ({} as Options)

		if (negative) {
			num = num.mul(negative1)
		}

		let fraction = num.mod(base).toString(10) // eslint-disable-line

		while (fraction.length < baseLength) {
			fraction = `0${fraction}`
		}

		if (!options.pad) {
			fraction = fraction.match(/^([0-9]*[1-9]|0)(0*)/)[1]
		}

		let whole = num.div(base).toString(10) // eslint-disable-line

		if (options.commify) {
			whole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
		}

		let value = `${whole}${fraction == '0' ? '' : `.${fraction}`}` // eslint-disable-line

		if (negative) {
			value = `-${value}`
		}

		return value
	}

	static fromDecimal(num: number | string): BN {
		const wei = web3.utils.toWei(String(num), 'ether')
		return new Currency(web3.utils.fromWei(wei.toString(), 'gwei'))
	}

	// toInt() : BN {
	// 	let num = this.numberToString(String(this.val)); // eslint-disable-line
	// 	let numBn = new BN(num)
	// 	let mult = (new BN("10")).pow(new BN(String(this.decimals)));

	// 	return numBn.mul(mult)

	// 	console.log('decimals', this.decimals)
	// 	const base = new BN('1' + String((new Array(this.decimals)).fill(0).join('')))
	// 	console.log('base', base.toString())
	// 	const baseLength = base.toString().length - 1 || 1;
	// 	console.log('base length', baseLength);

	// 	// Is it negative?
	// 	var negative = (num.substring(0, 1) === '-'); // eslint-disable-line
	// 	if (negative) {
	// 		num = num.substring(1);
	// 	}

	// 	if (num === '.') { throw new Error(`[ethjs-unit] while converting number ${this.val} to wei, invalid value`); }

	// 	// Split it into a whole and fractional part
	// 	var comps = num.split('.'); // eslint-disable-line
	// 	if (comps.length > 2) { throw new Error(`[ethjs-unit] while converting number ${this.val} to wei,  too many decimal points`); }

	// 	let whole : BN | string = comps[0],
	// 		fraction : BN | string = comps[1]; // eslint-disable-line

	// 	if (!whole) { whole = '0'; }
	// 	if (!fraction) { fraction = '0'; }
	// 	if (fraction.length > baseLength) { throw new Error(`[ethjs-unit] while converting number ${this.val} to wei, too many decimal places`); }

	// 	while (fraction.length < baseLength) {
	// 		fraction += '0';
	// 	}

	// 	whole = new BN(whole);
	// 	fraction = new BN(fraction);
	// 	var wei = (whole.mul(base)).add(fraction); // eslint-disable-line

	// 	if (negative) {
	// 		wei = wei.mul(negative1);
	// 	}

	// 	return new BN(wei.toString(10), 10);
	// }

	numberToString(n: number | string) {
		if (typeof n === 'string') {
			if (!n.match(/^-?[0-9.]+$/)) {
				throw new Error(
					`while converting number to string, invalid number value '${n}', should be a number matching (^-?[0-9.]+).`
				)
			}
			return n
		} else if (typeof n === 'number') {
			return String(n)
		}
		throw new Error(
			`while converting number to string, invalid number value '${n}' type ${typeof n}.`
		)
	}

	toString() {
		return this.val.toString()
	}
}
