import { DateTime } from "ts-luxon"
import { computed, ref } from "vue"

const msMap = new Map<number, any>()
const refCounter = new Map<number, number>()
const intervals = new Map<number, { wait: number, intvl: number }>()

const timeMatrix = {
	days: 1000 * 60 * 60 * 24,
	hours: 1000 * 60 * 60,
	minutes: 1000 * 60,
	seconds: 1000,
}

export function getBreakdown(ms: number) {
	let total = Math.abs(ms)
	const breakdown = [] as Object[]
	for (const u of Object.keys(timeMatrix)) {
		const unitKey = u.toLowerCase()
		const amt = Math.floor(total / timeMatrix[ unitKey ])
		const label = u
		const plural = (amt != 1)

		breakdown.push({
			unitName: u,
			unitKey,
			val: Math.abs(amt),
			isPast: ms <= 0,
			label: plural
				? label
				: label.replace(/s$/i, '')
		})

		total -= amt * timeMatrix[ unitKey ]
	}

	return breakdown
}

export function countdown(ts: DateTime) {
	const ms = Math.floor(ts.toSeconds())

	if ( ! msMap.get(ms) || ! intervals.get(ms)) {
		const duration = ref(getBreakdown(ts.diff(DateTime.now())))
		const finished = ref(isFinished(DateTime.now().toSeconds()))

		function updateDuration() {
			const now = DateTime.now()
			const diff = ts.diff(now)
			finished.value = isFinished(Math.floor(now.toSeconds()))
			duration.value = getBreakdown(diff)

			const interval = intervals.get(ms).wait
			if (Math.abs(diff.milliseconds) >= 60 * 1000) {
				if (interval < 60 * 1000) {
					stopInterval()
					startInterval( 60 * 1000)
				}
			} else if (Math.abs(diff.milliseconds) >= 60 * 60 * 1000) {
				if (interval < 60 * 60 * 1000) {
					stopInterval()
					startInterval( 60 * 60 * 1000 )
				}
			}
		}

		function isFinished(now: number) : boolean {
			return now > Math.floor(ts.toSeconds())
		}

		function destruct() {
			if (refCounter.get(ms) < 1) {
				stopInterval()
				refCounter.delete(ms)
			}
		}

		function startInterval(wait: number) {
			intervals.set(ms, {
				wait,
				intvl: setInterval(() => {
					updateDuration()
				}, 200)
			})
		}

		function stopInterval() {
			window.clearInterval(intervals.get(ms).intvl)
			intervals.delete(ms)
		}

		startInterval(100)

		msMap.set(ms, {
			duration,
			finished,
			destruct,
		})
	}

	refCounter.set(ms, (refCounter.get(ms) ?? 0) + 1)
	return msMap.get(ms)
}
