<template>
	<p>The bid you entered is too low.</p>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
	setup(props) {

		return {

		}
	}
})
</script>
