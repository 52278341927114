<template>
	<modal class="z-40">
		<template #default="handlers">
			<div
				class="bg-slate-50 rounded-xl pt-4 overflow-hidden drop-shadow-md w-full"
			>
				<h1 class="text-2xl font-bold text-center">Bid Preview</h1>

				<div class="px-4 mt-4 grid grid-cols-[1fr,2fr] gap-3">
					<div class="sm:row-span-2">
						<div
							class="bg-white p-3 rounded-xl border-2 border-slate-100"
						>
							<img
								:src="cdnURL(props.auction.images[0].uri)"
								class="rounded-xl"
							/>
						</div>
					</div>
					<div class="text-slate-600 text-sm space-y-1">
						<h2
							class="text-left text-slate-900 text-xl font-semibold"
						>
							{{ props.auction.title }}
						</h2>
					</div>

					<div class="text-left col-span-2 sm:col-auto">
						<div class="" v-if="!state.bidConfirmed">
							<span v-if="props.auction.bid_count > 0">
								Current Bid
							</span>
							<span v-else> Starting Bid </span>
							<p class="font-semibold text-2xl">
								{{ $format(currentBid.toDecimal()) }}
								{{ appStore.tokenSymbol }}
							</p>
							<p class="text-xs text-gray-500">
								w/Buyer's Premium:
								{{
									$format(
										currentBid
											.addPercentage(
												props.auction.buyer_premium_bps
											)
											.toDecimal()
									)
								}}
								{{ appStore.tokenSymbol }}
							</p>

							<div class="text-sm text-slate-500 mt-2">
								<p class="font-medium">
									Minimum bid:
									{{ $format(minimumBid.toDecimal()) }}
									{{ appStore.tokenSymbol }}
								</p>
								<p class="text-xs">
									w/Buyer's Premium:
									{{
										$format(
											minimumBid
												.addPercentage(
													props.auction
														.buyer_premium_bps
												)
												.toDecimal()
										)
									}}
									{{ appStore.tokenSymbol }}
								</p>
							</div>

							<div class="mt-2">
								<p class="font-bold whitespace-nowrap text-2xl">
									Your bid
								</p>

								<p
									v-if="state.error"
									class="text-xs text-red-500"
								>
									{{ state.error }}
								</p>

								<div
									class="flex items-center bg-white border-2 rounded-xl overflow-hidden space-x-2"
									:class="[
										state.error
											? 'border-red-500 text-red-500'
											: 'border-slate-300 ',
									]"
								>
									<input
										v-model="state.bid"
										@keyup="keyupValidator"
										@change="keyupValidator"
										type="number"
										max="5000000000"
										class="border-0 focus:ring-0 flex-auto text-xl p-2 outline-none text-right min-w-[75px]"
									/>
									<p class="text-gray-400 pr-4">
										{{ appStore.tokenSymbol }}
									</p>
								</div>
							</div>

							<div
								class="space-y-1 text-right text-gray-400 text-xs my-1 mx-2"
							>
								<div>
									<p>
										w/Buyer premium:
										{{
											$format(bidWithPremium.toDecimal())
										}}
										{{ appStore.tokenSymbol }}
									</p>
									<p>
										Your balance
										<i class="fa-solid fa-wallet"></i>
										{{
											$format(
												walletStore.currentBalance.toDecimal()
											)
										}}
										{{ appStore.tokenSymbol }}
									</p>
									<p>* All bids are spot bids.</p>
								</div>
							</div>
						</div>
						<div v-else class="mt-2">
							<p class="text-2xl text-left mx-6">
								Your bid was successful!
							</p>

							<div class="mx-6 mt-2">
								<p class="">Your bid</p>
								<p class="font-semibold text-2xl">
									{{ $format(state.bid) }}
									{{ appStore.tokenSymbol }}
								</p>
								<p class="text-xs text-gray-500">
									w/Buyer's Premium:
									{{
										$format(
											Currency.fromDecimal(state.bid)
												.addPercentage(
													props.auction
														.buyer_premium_bps
												)
												.toDecimal()
										)
									}}
									{{ appStore.tokenSymbol }}
								</p>

								<p class="text-gray-500 text-xs mt-2" v-if="state.transactionHash">
									<a
										:href="`https://etherscan.io/tx/${state.transactionHash}`"
										target="_blank"
									>
										Transaction
										{{
											`${state.transactionHash.substr(
												0,
												6
											)}...${state.transactionHash.substr(
												-6
											)}`
										}}
									</a>
								</p>
							</div>
						</div>
					</div>
				</div>

				<div
					class="bg-white border-t-2 border-slate-300 mt-6 px-4 pt-6 pb-4"
				>
					<div v-if="state.awaitingBid" class="text-center text-lg">
						<p class="text-gray-400">Confirming Bid</p>
						<loader class="mx-auto" />
					</div>
					<div v-else-if="state.bidConfirmed">
						<button
							class="btn-primary-lg bg-gray-300 text-slate-600 w-full"
							@click.stop="$emit('close')"
						>
							Close
						</button>
					</div>
					<div v-else class="grid grid-cols-2 gap-4">
						<button
							class="btn-lg bg-gray-300 text-slate-600"
							@click.stop="$emit('close')"
						>
							Cancel
						</button>
						<wallet-connected>
							<template #default>
								<button
									class="btn-primary-lg"
									@click="metamask.connect"
								>
									Connect
									<p class="sub-cta">Connect to Bid</p>
								</button>
							</template>
							<template #connected>
								<button
									class="btn-primary-lg"
									@click="handleBidClicked"
								>
									Submit Bid
								</button>
							</template>
						</wallet-connected>
					</div>
				</div>
			</div>
		</template>
	</modal>
	<authorize-bpx-modal
		v-if="state.showApprovalModal && state.currentAllowance == 0"
		@close="handleCloseAuthModal"
		@cancel="handleCancelAuthModal"
	/>
	<re-authorize-bpx-modal
		v-if="state.showApprovalModal && state.currentAllowance > 0"
		@close="handleCloseAuthModal"
		@cancel="handleCancelAuthModal"
	/>
	<error-modal
		v-show="null !== state.errorComponent"
		:component="state.errorComponent"
		:error="state.error ? state.error.toString() : ''"
		@close="state.errorComponent = null"
	/>
</template>
<script lang="ts">
import {
	defineComponent,
	PropType,
	computed,
	reactive,
	onMounted,
	unref,
} from 'vue'
import { useAppStore } from '@/stores/AppStore'
import Currency from '@/types/Currency'
import web3 from 'web3'
import metamask from '@/util/metamask'
import auction from '@/util/auction'
import escrow from '@/util/escrow'
import { cdnURL } from '@/util/cdn'
import AuthorizeBpxModal from '@/components/AuthorizeBpxModal.vue'
import ReAuthorizeBpxModal from '@/components/ReAuthorizeBpxModal.vue'
import ErrorModal from '@/components/Errors/ErrorModal.vue'
import GeneralErrorComponent from '@/components/Errors/GeneralError.vue'
import {
	codes,
	wasNotDeclined,
	isCustomError,
	buildErrorComponent,
	make as makeError,
} from '@/util/Errors'
import sleep from '@/util/sleep'
import BidTooLowVue from './Errors/BidTooLow.vue'
import { useAuctionStore } from '@/stores/AuctionStore'
import { useWalletStore } from '@/stores/WalletStore'
import { stat } from 'fs'
import BN from 'bn.js'
import IERC20 from '@/abi/IERC20.json'
import IAuction from '@/abi/IAuction.json'
import { ESCROW_ADDRESS, BPX_ADDRESS, UINT_MAX64 } from '@/util/escrow'
import { AUCTION_ADDRESS } from '@/util/auction'
import { debounce } from '@/util/debounce'
import currencyFormat from '@/util/currencyFormat'
import Bugsnag from '@bugsnag/js'

export default defineComponent({
	components: { AuthorizeBpxModal, ReAuthorizeBpxModal, ErrorModal },
	props: {
		auction: {
			required: true,
		},
		'current-bid': {
			required: true,
		},
	},
	emits: ['close'],
	setup(props) {
		const appStore = useAppStore()
		const auctionStore = useAuctionStore()
		const walletStore = useWalletStore()
		const BPXCurrency = metamask.loadContract(IERC20, BPX_ADDRESS)

		const AuctionContract = metamask.loadContract(IAuction, AUCTION_ADDRESS)

		const state = reactive({
			previousBid: props.currentBid,
			bid: 0,
			awaitingBid: false,
			showApprovalModal: false,
			errorComponent: null,
			bidConfirmed: false,
			transactionHash: null as string | null,
			error: null as Error | null,
			highBidderConfirmed: false,
			currentAllowance: 0,
		})

		onMounted(() => {
			state.bid = unref(props.currentBid)
		})

		const currentBid = computed(() => {
			return new Currency(
				props.auction.high_bid || props.auction.starting_bid
			)
		})

		const bidWithPremium = computed(() => {
			return Currency.fromDecimal(state.bid).addPercentage(
				props.auction.buyer_premium_bps
			)
		})

		function validateBid(): boolean {
			state.error = null
			const currentBalance = new BN(walletStore.currentBalance.toString())

			const minBid = new BN(minimumBid.value.toString())

			const currentBid = new BN(
				Currency.fromDecimal(state.bid).toString()
			)

			if (currentBalance.lt(currentBid)) {
				// console.error('insufficient funds')
				state.error = new Error(
					`You do not have enough ${appStore.tokenSymbol}`
				)
				return false
			}

			if (currentBid.lt(minBid)) {
				// console.error('too low')
				state.error = new Error(
					`Your bid must be more than ${currencyFormat(
						minimumBid.value.toDecimal()
					)} ${appStore.tokenSymbol}`
				)
				return false
			}

			if (walletStore.currentChain != walletStore.targetChain) {
				state.error = new Error(
					`You must be connected to the Ethereum Mainnet network to bid.`
				)
				return false
			}

			return true
		}
		const keyupValidator = debounce(validateBid, 50)

		async function handleBidClicked() {
			Bugsnag.leaveBreadcrumb('Bid Preview: bid clicked', null, 'log')
			const bidValid = validateBid()
			if (false == bidValid) {
				Bugsnag.leaveBreadcrumb('Bid Preview: bid invalid', null, 'log')
				// show generic error
				state.errorComponent = GeneralErrorComponent
				return
			}

			bid()
		}

		async function bid() {
			Bugsnag.leaveBreadcrumb(
				'Bid Preview: checking bpx approval',
				null,
				'log'
			)
			const bpxApproved = await isBpxApproved()

			if (false == bpxApproved) {
				Bugsnag.leaveBreadcrumb(
					'Bid Preview: showing approval modal',
					null,
					'log'
				)
				state.showApprovalModal = true
				return
			}

			try {
				Bugsnag.leaveBreadcrumb(
					'Bid Preview: executing bid',
					null,
					'log'
				)
				await executeBid()
			} catch (err) {
				Bugsnag.notify(err)
				state.awaitingBid = false

				if (isCustomError(err)) {
					state.errorComponent = buildErrorComponent(err)
				}
			}
		}

		async function isBpxApproved(): Promise<boolean> {
			const bpx = await metamask.loadContract(IERC20, BPX_ADDRESS)
			const allowance = await bpx.methods
				.allowance(metamask.state.wallet, ESCROW_ADDRESS)
				.call()

			state.currentAllowance = allowance
			Bugsnag.leaveBreadcrumb(
				'Bid Preview: got user bpx allowance',
				{
					allowance,
				},
				'log'
			)

			return new BN(bidWithPremium.value.toString()).lt(new BN(allowance))
		}

		async function executeBid() {
			const auction = await metamask.loadContract(
				IAuction,
				AUCTION_ADDRESS
			)

			const tx = await auction.methods.bid(
				props.auction.blockchain_auction_id,
				Currency.fromDecimal(state.bid).toString(),
				true
			)

			let gasEstimate = await tx.estimateGas({
				from: metamask.state.wallet,
				gas: 500_000,
			})

			gasEstimate = Math.ceil(gasEstimate * 1.3)

			state.awaitingBid = true
			const wait = sleep(2)
			Bugsnag.leaveBreadcrumb(
				'Bid Preview: sending bid to network',
				{
					auction: props.auction.blockchain_auction_id,
					title: props.auction.title,
				},
				'log'
			)
			const receipt = await tx
				.send({
					from: metamask.state.wallet,
					gas: gasEstimate,
					maxPriorityFeePerGas: null,
					maxFeePerGas: null,
				})
				.on('transactionHash', (hash) => {
					Bugsnag.leaveBreadcrumb(
						'Bid Preview: received bid tx hash',
						{
							hash,
						},
						'log'
					)
					state.transactionHash = hash
				})

			Bugsnag.leaveBreadcrumb('Bid Preview: bid successful', null, 'log')
			auctionStore.notifyBid(state.transactionHash)

			await wait
			let highBid
			let tries = 0
			Bugsnag.leaveBreadcrumb(
				'Bid Preview: fetching auction high bidder',
				null,
				'log'
			)
			do {
				try {
					highBid = await auction.methods
						.getAuctionMetadata(props.auction.blockchain_auction_id)
						.call()
					break
				} catch (e) {
					tries++

					if (tries > 3) {
						break
					}

					await sleep(1)
				}
			} while (true)

			state.awaitingBid = false

			try {
				state.highBidderConfirmed =
					highBid &&
					highBid.highBidder.toLowerCase() ==
						metamask.state.wallet?.toLowerCase()
			} catch (e) {
				// don't care
			}

			if (state.highBidderConfirmed) {
				Bugsnag.leaveBreadcrumb(
					'Bid Preview: high bidder confirmed',
					null,
					'log'
				)
			} else {
				Bugsnag.leaveBreadcrumb(
					'Bid Preview: high bidder couldnt be confirmed',
					null,
					'log'
				)
			}

			state.bidConfirmed = true
		}

		async function handleCloseAuthModal() {
			state.showApprovalModal = false
			await bid()
		}

		async function handleCancelAuthModal() {
			state.showApprovalModal = false
		}

		const minimumBid = computed(() => {
			return currentBid.value.add(props.auction.bid_increment)
		})

		function checkErrors() {
			if (state.bid == '' || state.bid == 0) {
				state.error = `Bid must be greated than 0 ${appStore.tokenSymbol}`
				Bugsnag.leaveBreadcrumb('Error: ' . state.error)
				return
			}

			const bid = new BN(Currency.fromDecimal(state.bid).toString())
			const balance = new BN(walletStore.currentBalance.toString())

			if (balance.lt(bid)) {
				state.error = `You do not have enough ${appStore.tokenSymbol}`
				Bugsnag.leaveBreadcrumb('Error: ' . state.error)
				return
			}

			if (bid.lt(new BN(minimumBid.value.toString()))) {
				state.error = `Bid must be at least ${currencyFormat(
					minimumBid.value.toDecimal()
				)} ${appStore.tokenSymbol}`
				Bugsnag.leaveBreadcrumb('Error: ' . state.error)
				return
			}

			state.error = null
		}

		return {
			props,
			state,
			appStore,
			walletStore,
			metamask,
			currentBid,
			Currency,
			bidWithPremium,
			bid,
			handleCloseAuthModal,
			handleCancelAuthModal,
			minimumBid,
			checkErrors,
			handleBidClicked,
			keyupValidator,
			cdnURL,
		}
	},
})
</script>
