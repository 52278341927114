<template>
	<auction-listing-condensed :auction="auction">
		<template #default>
			<div class="flex-grow flex flex-col">
				<p v-if="auction.bids.length == 0"
					class="text-slate-500">
					No bids on this item yet
				</p>
				<p v-else v-for="bid in auction.bids.slice(0, props.bidLimit)"
					class="font-semibold mt-2" :key="`a-${auction.id}-b-${bid.tx_hash}-${bid.bidder}`">
					{{ $format((new Currency(bid.bid)).toDecimal()) }}
					{{ appStore.tokenSymbol }}
					<span class="font-normal text-sm text-slate-500"><timeago :datetime="bid.bid_at.toISO()" /></span>
					<span class="font-normal text-sm text-slate-500">
						by <wallet-address
							v-if="! profileStore.wallet || (profileStore.wallet.toLowerCase() != bid.bidder.toLowerCase())"
							:address="bid.bidder"
						/>
						<span v-else>You</span>
					</span>
					&nbsp;
					<br class="md:hidden" />
					<span class="font-normal text-sm text-slate-400">
					{{ $format((new Currency(bid.bid)).addPercentage(auction.buyer_premium_bps).toDecimal()) }}
					{{ appStore.tokenSymbol }}
					w/Buyer's premium
					</span>
				</p>
			</div>
		</template>
	</auction-listing-condensed>
</template>
<script lang="ts">
import { useAppStore } from '@/stores/AppStore'
import { defineComponent, PropType, computed } from 'vue'
import Currency from '@/types/Currency'
import TimeRemaining from './TimeRemaining.vue'
import Pluralize from '@/components/Pluralize.vue'
import AuctionListingCondensed from '@/components/AuctionListingCondensed.vue'
import { useProfileStore } from '@/stores/ProfileStore'
import { Auction } from '@/types/Auction'

export default defineComponent({
	components: { TimeRemaining, Pluralize, AuctionListingCondensed },
	props: {
		auction: {
			required: true,
			type: Object as PropType<Auction>
		},
		bidLimit: {
			required: false,
			default: () => 5,
			type: Number
		}
	},
	setup(props) {
		const appStore = useAppStore()
		const profileStore = useProfileStore()

		const auction = computed(() => {
			return props.auction
		})

		return {
			props,
			Currency,
			appStore,
			profileStore,
			auction,
		}
	},
})
</script>
