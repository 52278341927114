<template>
	<div class="fixed top-0 left-0 right-0 flex justify-center z-50">
		<div class="text-center space-x-4 my-2">
			<button class="text-sm bg-gray-900 py-1 px-4 rounded text-white shadow"
				@click="newBid">Send New Bid Notification</button>
			<button class="text-sm bg-red-400 py-1 px-4 rounded text-gray-900 shadow"
				@click="outBid">Send Outbid Notification</button>
		</div>
	</div>
</template>
<script lang="ts">
import { useAuctionStore } from "@/stores/AuctionStore";
import { useNotificationStore } from "@/stores/NotificationStore";
import { useWalletStore } from "@/stores/WalletStore";
import { Auction } from "@/types/Auction";
import BN from "bn.js";
import { DateTime } from "ts-luxon";
import { defineComponent, PropType } from "vue";

export default defineComponent({
	setup(props) {
		const auctionStore = useAuctionStore()
		const notificationStore = useNotificationStore()
		const walletStore = useWalletStore()

		function newBid() {
			let auction = JSON.parse(JSON.stringify(auctionStore.auction))
			if ( ! auction) {
				let count = auctionStore.auctions.length
				let rand = Math.floor(Math.random() * count)
				auction = auctionStore.auctions[ rand ]
			}

			let lastBid = {
				auction_id: auction.id,
				blockchain_auction_id: auction.blockchain_auction_id,
				bid: (auction.high_bid || 0),
				bidder: "0x01d00B1dDe200000000000000000001d00B1dDe2",
				tx_hash: "0x000001337000000000133700000000013370000",
				bid_at: DateTime.now().minus({ seconds: 3600 }),
			}

			auction.high_bidder = '0x13370b1DDe200000000000013370b1DDe2000000'
			auction.high_bid = (new BN(auction.high_bid || 0)).add(new BN(auction.bid_increment)).toString(),
			auction.last_bid_at = DateTime.now()

			sendNotification(auction, lastBid)
		}

		function outBid() {
			let auction = JSON.parse(JSON.stringify(auctionStore.auction))
			if ( ! auction) {
				let count = auctionStore.auctions.length
				let rand = Math.floor(Math.random() * count)
				auction = auctionStore.auctions[ rand ]
			}

			let lastBid = {
				auction_id: auction.id,
				blockchain_auction_id: auction.blockchain_auction_id,
				bid: (auction.high_bid || 0),
				bidder: walletStore.wallet,
				tx_hash: "0x000001337000000000133700000000013370000",
				bid_at: DateTime.now().minus({ seconds: 3600 }),
			}

			auction.high_bidder = '0x13370b1DDe200000000000013370b1DDe2000000'
			auction.high_bid = (new BN(auction.high_bid || 0)).add(new BN(auction.bid_increment)).toString(),
			auction.last_bid_at = DateTime.now()

			sendNotification(auction, lastBid)
		}

		function sendNotification(auction: Auction, lastBid: any) {
			notificationStore.dispatchBidNotification(auction, lastBid)
		}

		return {
			props,
			newBid,
			outBid,
		}
	}
})
</script>
