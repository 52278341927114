import { defineStore } from 'pinia'
import { Taxonomy, Tag } from '@/types/Taxonomy'
import api from '@/util/api'
import sleep from '@/util/sleep'
import BN from 'bn.js'
import { AuctionFilter, AVAILABLE_FILTERS } from '@/types/AuctionFilter'

interface AuctionFilterStoreState {
	loaded: boolean
	selected: Map<string, boolean>
	modified: Map<string, boolean>
	counts: Map<string, number>
}

export const useAuctionFilterStore = defineStore('auctionFilter', {
	state: () =>
		({
			loaded: true,
			selected: new Map(),
			modified: new Map(),
			counts: new Map(),
		} as AuctionFilterStoreState),
	actions: {
		toggleModifiedFilter(id: string) {
			const active = this.modified.get(id)
			this.modified.set(id, !active)
		},
		applyFilters() {
			this.selected = new Map(
				JSON.parse(
					JSON.stringify(Array.from(this.modified.entries()))
				)
			)
		},
		forceRefresh() {
			this.selected.set('refresh', this.selected.get('refresh') ?? 1)
		},
		resetFilters() {
			this.selected = new Map()
			this.modified = new Map()
		},
	},
	getters: {
		allFilters(): AuctionFilter[] {
			return AVAILABLE_FILTERS
		},
		activeFilters() {
			let result: any = {}
			this.selected.forEach((value, key) => {
				if (value) {
					result[key] = value
				}
			})

			return result
		},
	},
})
