<template>
	<modal class="z-50">
		<div class="bg-slate-50 rounded-xl pt-4 overflow-hidden drop-shadow-md w-full">
			<h1 class="text-2xl text-center font-bold">Congratulations!</h1>
			<p class="text-center text-lg px-6 mt-4">
				You Sold Lot #{{ props.auction.id }} <span class="italic">{{ props.auction.title }}</span>
				for
				<br>
				<span class="text-2xl">
					{{ $format((new Currency(props.auction.high_bid)).toDecimal()) }}
					{{ appStore.tokenSymbol }}
				</span>
			</p>

			<div class="max-w-[50%] mx-auto rounded-lg overflow-hidden my-4">
				<img :src="$cdn(props.auction.images[0].uri)" />
			</div>

			<div class="pt-4">
				<div v-if="false == state.txConfirmed" class="text-center mx-6">
					<label for="address">
						<span class="text-xl">Where would you like to send your {{ appStore.tokenSymbol }}?</span>
					</label>

					<div
						class="mt-2 flex items-center bg-white border-2 rounded-xl overflow-hidden space-x-2"
					>
						<input
							id="address"
							v-model="state.destAddress"
							@keyup="checkENS"
							type="text"
							class="border-0 focus:ring-0 flex-auto text-xs sm:text-base p-2 outline-none text-center font-mono"
						/>
					</div>
					<p class="mt-2 text-sm text-gray-500 text-center">{{ appStore.tokenSymbol }} will be delivered to address:</p>
					<p v-if=" ! state.addressError" class="text-xs text-sky-800">{{ state.deliveryAddress }}</p>
					<p v-else class="text-xs text-red-500">{{ state.addressError }}</p>

					<div class="mt-3 text-xs text-gray-500 italic text-left space-y-2">
						<p>
							By claiming the {{ appStore.tokenSymbol }} Proceeds for this Auction Lot you acknowledge
							that the address you entered is correct,and understand that BPX Collective, or any other party,
							does not have the ability to recover any {{ appStore.tokenSymbol }} sent to an incorrect address,
							nor provides any warantee or guarantee should the {{ appStore.tokenSymbol }} be sent to the wrong address.
						</p>
						<p>
							Use extreme caution when entering a delivery address, and double-check
							that it is correct before clicking "Claim" below.
						</p>
					</div>
				</div>
				<div v-else>
					<p class="text-center text-xl font-semibold">
						Your {{ appStore.tokenSymbol }} has been delivered!
					</p>

					<p class="text-center mt-2">
						View the transaction here:
						<br>
						<a :href="`https://etherscan.io/tx/${state.transactionHash}`"
							target="_blank" class="href-primary font-mono"
						>{{ `${state.transactionHash.substr(0, 8)}...${state.transactionHash.substr(-8)}` }}</a>
					</p>
				</div>
			</div>

			<div class="bg-white border-t-2 border-slate-300 mt-6 px-4 pt-6 pb-4">
				<div v-if="state.awaitingTx"
					class="text-center text-lg"
				>
					<p class="text-gray-400">Transferring {{ appStore.tokenSymbol }}</p>
					<loader class="mx-auto" />
				</div>
				<div v-else-if="state.txConfirmed">
					<button class="btn-primary-lg bg-gray-300 text-slate-600 w-full"
						@click.stop="$emit('close')">
						Close
					</button>
				</div>
				<div v-else class="grid grid-cols-2 gap-4">
					<button class="btn-lg bg-gray-300 text-slate-600"
						@click.stop="$emit('close')">
						Cancel
					</button>
					<wallet-connected>
						<template #default>
							<button class="btn-primary-lg"
								@click="metamask.connect">
								Connect
								<p class="sub-cta">Connect to Bid</p>
							</button>
						</template>
						<template #connected>
							<button class="btn-primary-lg"
								:disabled=" state.addressError || ! state.deliveryAddress"
								@click="handleClaimClicked">
								Claim {{ appStore.tokenSymbol }}
							</button>
						</template>
					</wallet-connected>
				</div>
			</div>
		</div>
	</modal>
</template>
<script lang="ts">
import { useWalletStore } from "@/stores/WalletStore";
import { Auction } from "@/types/Auction";
import { debounce } from "@/util/debounce";
import sleep from "@/util/sleep";
import Bugsnag from "@bugsnag/js";
import { defineComponent, PropType, reactive, unref } from "vue";
import Web3 from 'web3/dist/web3.min.js';
import { AUCTION_ADDRESS } from '@/util/auction'
import IAuction from '@/abi/IAuction.json'
import metamask from '@/util/metamask'
import Currency from "@/types/Currency";
import { useAppStore } from "@/stores/AppStore";

export default defineComponent({
	props: {
		auction: {
			required: true,
			type: Object as PropType<Auction>,
		}
	},
	emits: ['close'],
	setup(props) {
		const appStore = useAppStore()
		const walletStore = useWalletStore()
		const w3 = new Web3(import.meta.env.VITE_ENS_PROVIDER)

		const state = reactive({
			destAddress: unref(walletStore.wallet),
			deliveryAddress: unref(walletStore.wallet),
			addressError: null as string | null,
			awaitingTx: false,
			txConfirmed: false,
			transactionHash: null as string | null,
		})

		const checkENS = debounce(async function() {
			if (state.destAddress?.match(/.+\.eth$/)) {4
				try {
					const address = await w3.eth.ens.getAddress(state.destAddress)
					state.deliveryAddress = address;
					state.addressError = null;
					Bugsnag.leaveBreadcrumb('ClaimProceedsModal: using ens to deliver claim', {
						wallet: walletStore.wallet,
						ens: state.destAddress,
						address,
						auction: props.auction.title,
						auction_id: props.auction.id,
					}, 'log')
					return
				} catch (e) {
					// ens didn't resolve
					state.addressError = 'Invalid ENS address.'
				}
			} else if (state.destAddress?.match(/^(0x)?[0-9a-f]{40}$/i)) {
				state.deliveryAddress = unref(state.destAddress)
				state.addressError = null;
				Bugsnag.leaveBreadcrumb('ClaimProceedsModal: delivering to custom address', {
					wallet: walletStore.wallet,
					address: state.deliveryAddress,
					auction: props.auction.title,
					auction_id: props.auction.id,
				}, 'log');
				return
			} else {
				state.addressError = 'Invalid ETH address or ENS name.'
			}
			Bugsnag.leaveBreadcrumb('ClaimProceedsModal: entered invalid address', {
				wallet: walletStore.wallet,
				address: state.destAddress,
				auction: props.auction.title,
				auction_id: props.auction.id,
			})
			state.deliveryAddress = null
		}, 75);

		async function handleClaimClicked() {
			Bugsnag.leaveBreadcrumb('ClaimProceedsModal: clicked claim', {
				wallet: walletStore.wallet,
				auction: props.auction.title,
				auction_id: props.auction.id,
				deliveryAddress: state.deliveryAddress,
			});

			try {
				await execClaim()
			} catch (e) {
				state.awaitingTx = false;
				Bugsnag.notify(e)
				console.error(e.message)
				if (e.message.includes('Internal JSON-RPC error.')) {
					console.error(JSON.parse(e.message.replace('Internal JSON-RPC error.', '').trim()))
				}
				alert('An unexpected error occurred');
			}
		}

		async function execClaim() {
			const auction = await metamask.loadContract(
				IAuction,
				AUCTION_ADDRESS
			)

			const tx = await auction.methods.claimProceeds(props.auction.blockchain_auction_id, state.deliveryAddress);
			let gasEstimate = await tx.estimateGas({
				from: metamask.state.wallet,
				gas: 500_000,
			})
			gasEstimate = Math.ceil(gasEstimate * 1.3)

			state.awaitingTx = true;
			const wait = sleep(2)
			Bugsnag.leaveBreadcrumb('ClaimProceedsModal: sending claim tx to network', {
				wallet: walletStore.wallet,
				auction: props.auction.title,
				auction_id: props.auction.id,
				deliveryAddress: state.deliveryAddress,
			}, 'log')

			const receipt = await tx.send({
				from: metamask.state.wallet,
				gas: gasEstimate,
				maxPriorityFeePerGas: null,
				maxFeePerGas: null,
			}).on('transactionHash', (hash) => {
				Bugsnag.leaveBreadcrumb('ClaimProceedsModal: received claim tx hash', {
					hash,
					wallet: walletStore.wallet,
					auction: props.auction.title,
					auction_id: props.auction.id,
					deliveryAddress: state.deliveryAddress,
				})
				state.transactionHash = hash
			})

			Bugsnag.leaveBreadcrumb('ClaimProceedsModal: lot claim successful', {
				hash: state.transactionHash,
				wallet: walletStore.wallet,
				auction: props.auction.title,
				auction_id: props.auction.id,
				deliveryAddress: state.deliveryAddress,
			})

			await wait;
			state.txConfirmed = true
			state.awaitingTx = false
		}

		return {
			appStore,
			props,
			state,
			Currency,
			checkENS,
			handleClaimClicked,
		}
	}
})
</script>
