<template>
	<bid-history-item
		:bid="props.bid"
	>
		<template #actions>
			<div class="space-x-4 mt-4">
				<router-link custom :to="{ name: 'auction', params: { auction: bid.auction_id, group: auction.auction_group.slug }}"
					v-slot="{ navigate }">
					<button class="btn shadow-none inline-block text-slate-400 hover:text-slate-600 hover:bg-gray-200 border border-slate-200 rounded-lg"
						@click="navigate" role="link">
						<i class="fa-solid fa-eye"></i> View
					</button>
				</router-link>
			</div>
		</template>
	</bid-history-item>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import BidHistoryItem from "./BidHistoryItem.vue";
import { Auction, Bid } from "@/types/Auction";

export default defineComponent({
    components: { BidHistoryItem },
	props: {
		bid: {
			required: true,
			type: Object as PropType<Bid>,
		},
		auction: {
			required: true,
			type: Object as PropType<Auction>,
		}
	},
	setup(props) {
		console.log(props)

		return {
			props,
			bid: props.bid,
			auction: props.auction,
		}
	}
})
</script>
