<template>
	<div class="" v-if="auction">
		<p class="text-lg font-semibold">{{ auction.title }}</p>
		<time-remaining :auction="auction" />
		<div class="flex space-x-4 mt-2">
		<div class="max-w-[6rem] aspect-square bg-slate-200/80 p-4 rounded-xl flex items-center justify-center">
			<img :src="cdnURL(auction.images[0].uri)" class="border-2 border-slate-300 rounded-lg overflow-hidden" />
		</div>
		<div class="flex-grow flex flex-col">
			<p class="font-semibold mt-2">
				{{ $format(bid.toDecimal()) }}
				{{ appStore.tokenSymbol }}
				<span class="font-normal text-sm text-slate-500"><timeago :datetime="props.bid.bid_at.toISO()" /></span>
				<span class="font-normal text-sm text-slate-500">
					by <wallet-address
						v-if="! profileStore.wallet || (profileStore.wallet.toLowerCase() != props.bid.bidder.toLowerCase())"
						:address="props.bid.bidder"
					/>
					<span v-else>You</span>
				</span>
				&middot;
				<span class="font-normal text-sm text-slate-500">
				{{ $format(bid.addPercentage(auction.buyer_premium_bps).toDecimal()) }}
				{{ appStore.tokenSymbol }}
				w/Buyer's premium
				</span>
			</p>
			<!-- <p class="text-sm text-slate-500">
			</p>
			<div class="text-sm text-slate-500/90 leading-none space-x-1 mt-1 flex-grow">
				<i class="fa-fw fa-regular fa-clock text-slate-400"></i>
				Bid<timeago :datetime="props.bid.bid_at.toISO()" />
			</div> -->
		</div>
		</div>
			<!-- <slot name="actions"></slot> -->
	</div>
</template>
<script lang="ts">
import { useAppStore } from '@/stores/AppStore'
import { defineComponent, PropType, computed } from 'vue'
import Currency from '@/types/Currency'
import { countdown } from '@/util/countdownAuction'
import { useAuctionStore } from '@/stores/AuctionStore'
import { cdnURL } from '@/util/cdn'
import TimeRemaining from './TimeRemaining.vue'
import { useProfileStore } from '@/stores/ProfileStore'

export default defineComponent({
	components: { TimeRemaining },
	props: {
		bid: {
			required: true,
		},
	},
	setup(props) {
		const appStore = useAppStore()
		const auctionStore = useAuctionStore()
		const profileStore = useProfileStore()

		const auction = computed(() => {
			if (props.bid.auction) {
				return props.bid.auction;
			}

			if (!auctionStore.auctions || 0 == auctionStore.auctions.length) {
				return null
			}

			return (
				auctionStore.auctions.filter(
					(a) => a.id == props.bid.auction_id
				)[0] || null
			)
		})

		const bid = computed(() => {
			return new Currency(props.bid.bid)
		})

		return {
			props,
			Currency,
			appStore,
			profileStore,
			auction,
			bid,
			cdnURL,
		}
	},
})
</script>
