import { defineStore } from 'pinia'
import { useProfileStore } from '@/stores/ProfileStore'
import { useWalletStore } from '@/stores/WalletStore'
import { AuthToken, useAuthStore } from '@/stores/AuthStore'
import { Auction } from '@/types/Auction'
import { useAuctionStore } from './AuctionStore'

export enum NotificationType {
	Outbid,
	Standard
}

export interface Notification {
	auction: Auction
	lastBid: any
	kind: NotificationType
}

export const useNotificationStore = defineStore('notification', {
	state: () => ({
		toastNotifications: [] as Notification[],
		feedNotifications: [],
	}),

	actions: {
		dispatchBidNotification(auction: Auction, lastBid: any) {
			const profileStore = useProfileStore()
			const walletStore = useWalletStore()
			const auctionStore = useAuctionStore()

			if (walletStore.wallet) {

				if (auction.high_bidder?.toLowerCase() == walletStore.wallet.toLowerCase()) {
					// the user cast this bid themself, so we don't need to alert them about it.
					return false;
				}

				if (lastBid && lastBid.bidder.toLowerCase() == walletStore.wallet?.toLowerCase()) {
					// the current user was just outbid for this item
					this.toastNotifications.push({
						auction,
						lastBid,
						kind: NotificationType.Outbid,
					})

					return
				}
			}

			if (auctionStore.auction && auctionStore.auction.id == auction.id) {
				this.toastNotifications.push({
					auction,
					lastBid,
					kind: NotificationType.Standard,
				})

				return
			}

			if ( ! walletStore.wallet) {
				return;
			}

			const token = AuthToken.fetchForWallet(walletStore.wallet, true);

			if ( ! token) {
				return
			}

			if (0 == profileStore.watched.length) {
				return
			}

			profileStore.watched.map(watchedAuction => {
				if (watchedAuction.id == auction.id) {
					// the user watches this auction, so we can notify them
					this.toastNotifications.push({
						auction,
						lastBid,
						kind: NotificationType.Standard,
					});
				}
			})

			return
		}
	},

	getters: {

	}
})
