<template>
	<div>
		<auction-listing-condensed
			:auction="auction"
			:show-buyer-premium="false"
			class="flex-1">
			<template #buttons>
				<div class="grid grid-cols-2 gap-4 md:flex">
					<div>
						<button class="block w-full btn bg-slate-200 whitespace-nowrap" v-if="canClaim"
							@click="onClaim(auction)"
							:disabled="! canClaim">
							Claim {{ appStore.tokenSymbol }}
							<i class="fa-regular fa-piggy-bank" />
						</button>
						<button disabled v-else
							class="block w-full shadow text-slate-400 font-normal btn bg-gray-200 whitespace-nowrap">
							Claimed
							<i class="fa-regular fa-piggy-bank" />
						</button>
					</div>
					<div>
						<router-link
							custom
							:to="{ name: 'auction', params: { auction: auction.id, group: auction.auction_group.slug }}"
							v-slot="{ navigate }">
							<button role="link" @click="navigate"
								class="block w-full font-base btn-primary flex-shrink-0 whitespace-nowrap shadow">
								View
								<i class="fa-regular fa-eye"></i>
							</button>
						</router-link>
					</div>
				</div>
			</template>

			<template #default>
				<div class="flex-grow flex flex-col">
					<div class="flex-grow">
						<p class="text-xl font-semibold">
							{{ $format((new Currency(auction.high_bid || auction.starting_bid)).toDecimal()) }}
							{{ appStore.tokenSymbol }}
						</p>
					</div>
					<p class="mt-2 text-sm italic text-slate-500" v-if="auction.proceeds_claimed_at">
						Claimed on {{ auction.proceeds_claimed_at.toFormat('D @ t') }}
						<span v-if="auction.proceeds_claim_tx_hash">
							<br>
							<a :href="`https://etherscan.io/tx/${auction.proceeds_claim_tx_hash}`" class="href-primary"
								target="_blank"
								>View Transaction
							</a>
						</span>
					</p>
				</div>
			</template>
		</auction-listing-condensed>

		<claim-proceeds-modal
			v-if="state.claimAuction"
			@close="state.claimAuction = null"
			:auction="state.claimAuction"
			:key="`auction-claim-${state.claimAuction? state.claimAuction.id : 'null'}`"
		/>
	</div>
</template>
<script lang="ts">
import { Auction, StatusEnded } from "@/types/Auction";
import { defineComponent, PropType, onMounted, ref, reactive, computed } from "vue";
import AuctionListingCondensed from "@/components/AuctionListingCondensed.vue";
import ClaimProceedsModal from '@/components/ClaimProceedsModal.vue'
import { useProfileStore } from "@/stores/ProfileStore";
import { useAuthStore } from "@/stores/AuthStore";
import { useAppStore } from "@/stores/AppStore";
import { DateTime } from "ts-luxon";
import Currency from "@/types/Currency";
import { useAuctionStatusStore } from "@/stores/AuctionStatusStore";

export default defineComponent({
	components: { AuctionListingCondensed, ClaimProceedsModal },
	props: {
		auction: {
			required: true,
			type: Object as PropType<Auction>,
		}
	},
	setup(props) {
		const profileStore = useProfileStore()
		const authStore = useAuthStore()
		const appStore = useAppStore()
		const auctionStatusStore = useAuctionStatusStore()

		const state = reactive({
			claimAuction: null as Auction | null,
		})

		onMounted(async() => {
			const t = await authStore.authToken()
			profileStore.loadAccount(
				t
			);
		})

		const auctions = computed(() => {
			if ( ! profileStore.owned || 0 == profileStore.owned.length) {
				return []
			}

			return profileStore.owned
		});


		function onClaim(auction) {
			state.claimAuction = auction
		}

		const canClaim = computed(() => {
			return props.auction.proceeds_claimed_at == null
				&& auctionStatusStore.status(props.auction.id) == StatusEnded
		})

		return {
			props,
			auction: props.auction,
			Currency,
			state,
			appStore,
			onClaim,
			canClaim,
		}
	}
})
</script>
