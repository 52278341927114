<template>
	<div>
		<h1 class="border-b-2 border-slate-200 pb-1 flex items-end space-x-2 justify-between">
			<p class="text-xl font-bold">Your Bids</p>
		</h1>

		<div class="space-y-8 mt-4">

		<p v-if="profileStore.bids.length == 0"
			class="text-slate-500 text-lg text-center">
			You haven't made any bids yet
		</p>
		<div v-else
			class="space-y-4 lg:space-y-0 lg:grid lg:grid-cols-2 gap-8 items-start">
			<!-- class="border-b-2 border-slate-200 pb-4 h-full px-4" -->
		<auction-listing-condensed
			class="md:border border-slate-200 py-4 md:p-6 h-full rounded-xl"
			v-for="{ bid, auction } in profileStore.bids"
			:key="`auction-${auction.id}-bid-${bid.tx_hash}`"
			:auction="auction">

			<template #default>
				<div class="flex-grow flex flex-col">
					<div class="flex-grow">
						<p>Your bid</p>
						<p class="text-xl font-semibold">
							{{ $format((new Currency(bid.bid)).toDecimal()) }}
							{{ appStore.tokenSymbol }}
						</p>
						<p class="text-sm text-slate-500 text-italic">
							{{ $format((new Currency(bid.bid)).addPercentage(auction.buyer_premium_bps).toDecimal()) }}
							{{ appStore.tokenSymbol }}
							w/Buyer's Premium
						</p>
					</div>
					<div v-if="auction.high_bidder.toLowerCase() == bid.bidder.toLowerCase() && auction.high_bid == bid.bid">
						<p class="bg-green-600 text-white px-4 py-2 whitespace-nowrap rounded-xl text-sm flex items-center space-x-2">
							<i class="fa fa-regular fa-award"></i>
							&nbsp;
							Winning Bid
						</p>
					</div>
				</div>
			</template>
		</auction-listing-condensed>
		</div>
<!--
		<div class="border-b-2 border-slate-300 mt-4">
			<h2 class="text-xl">Bid History</h2>
		</div>
		<div v-if=" ! bids.length">
			<p class="text-gray-500">You have not made any bids</p>
		</div>
		<div v-else class="mt-2"> -->
			<!-- <router-link v-for="bid in bids"
				:to="{ name: 'auction', params: { auction: bid.auction_id, group: auctionGroup(bid.auction_id).slug }}" -->
				<!-- class="p-2 block hover:bg-slate-100 rounded-lg"> -->
				<!-- <bid-history-item v-for="bid in bids" :key="`bid-${bid.id}`"
					class="py-4 border-b-2 border-slate-200"
					:bid="bid">
					<template #actions>
						<div class="space-x-4 mt-4">
							<router-link custom :to="{ name: 'auction', params: { auction: bid.auction_id, group: bid.auction?.auction_group.slug }}"
								v-slot="{ navigate }">
								<button class="btn shadow-none inline-block text-slate-400 hover:text-slate-600 hover:bg-gray-200 border border-slate-200 rounded-lg"
									@click="navigate" role="link">
									<i class="fa-solid fa-eye"></i> View
								</button>
							</router-link>
						</div>
					</template>
				</bid-history-item> -->
			<!-- </router-link> -->
		</div>
	</div>
</template>
<script lang="ts">
import { computed, defineComponent, PropType, onMounted } from "vue";
import AuctionListingCondensed from "@/components/AuctionListingCondensed.vue";

import AuctionListingCondensed from "@/components/AuctionListingCondensed.vue";
import { useAuctionStore } from "@/stores/AuctionStore";
import { useProfileStore } from "@/stores/ProfileStore";
import { useAuthStore } from "@/stores/AuthStore";
import { useAppStore } from "@/stores/AppStore";
import Currency from "@/types/Currency";

export default defineComponent({
	components: { AuctionListingCondensed },
	setup(props) {
		const profileStore = useProfileStore()
		const authStore = useAuthStore()
		const appStore = useAppStore()

		onMounted(async() => {
			const t = await authStore.authToken()
			return profileStore.loadAccount(
				t
			);
		})

		return {
			Currency,
			profileStore,
			appStore,
		}
	}
})
</script>
