<template>
	<div class="leading-none text-xs">
		<div v-if="props.auction?.high_bidder.toLowerCase() == walletStore.wallet?.toLowerCase()"
			class="inline-block rounded bg-green-600 text-green-100 text-xs font-medium px-4 py-1 leading-none">
			High Bidder
		</div>
		<div v-else-if="props.auction?.bids.filter(b => b.bidder.toLowerCase() == walletStore.wallet?.toLowerCase()).length"
			class="inline-block rounded bg-red-500 text-gray-50 text-xs font-medium px-4 py-1 leading-none">
			Outbid
		</div>
	</div>
</template>
<script lang="ts">
import { useWalletStore } from "@/stores/WalletStore";
import { Auction } from "@/types/Auction";
import { defineComponent, PropType } from "vue";

export default defineComponent({
	props: {
		auction: {
			required: true,
			type: Object as PropType<Auction>,
		}
	},
	setup(props) {
		const walletStore = useWalletStore()

		return {
			props,
			walletStore,
		}
	}
})
</script>
