<template>
	<span :title="props.address">{{ ens || $shorten(props.address.toUpperCase().replace('X', 'x')) }}</span>
</template>
<script lang="ts">
import useEns from "@/util/ens";
import { computed, defineComponent, onMounted, PropType } from "vue";

export default defineComponent({
	props: {
		address: {
			type: String,
			required: true
		}
	},
	setup(props) {
		const { reverse, resolved } = useEns(import.meta.env.VITE_ENS_PROVIDER)

		onMounted(() => {
			reverse(props.address)
		})

		const ens = computed(() => {
			return resolved[ props.address.toLowerCase() ] || null
		})

		return {
			props,
			ens
		}
	}
})
</script>
