<template>
	<div v-if="similarAuctions.length">
		<slot name="default">
			<h2 class="mx-2 text-lg font-bold mb-2 border-b-2 border-slate-200">
				Similar Auctions
			</h2>
		</slot>
		<div class="overflow-x-auto flex space-x-4 mt-6 scroll-px-4">
			<div v-for="auction in similarAuctions" :key="`auction-tile-${auction.id}`"
				class="first:ml-2 last:mr-2 flex-none lg:max-w-[15%] md:max-w-[30%] sm:max-w-[40%] max-w-[55%] bg-white rounded-md">
				<auction-tile
					:auction="auction"
				/>
			</div>
		</div>
	</div>
</template>
<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import AuctionTile from "@/components/AuctionTile.vue";

export default defineComponent({
	components: { AuctionTile },
	props: {
		auction: {
			required: true,
			type: Object as PropType<Auction>,
		},
		auctions: {
			required: true,
			type: Array as PropType<Array<Auction>>,
		},
	},
	setup(props) {
		const similarAuctions = computed(() =>{
			const baseTagIds = props.auction.tags.map(t => t.id)
			const similarMap = {}
			return props.auctions.filter((auction) => {
				const tmpTags = auction.tags.map(t => t.id)

				let similar = 0;
				for (const tid of tmpTags) {
					if (baseTagIds.indexOf(tid) >= 0) {
						similar ++
					}
				}

				if (auction.id != props.auction.id
					&& similar
					&& auction.auction_group.id == props.auction.auction_group.id) {
						similarMap[ auction.id ] = similar;
						return true;
				}

				return false;
			}).sort((left, right) => similarMap[ left.id ] - similarMap[ right.id ])
			.slice(0, 10)
		})

		return {
			props,
			similarAuctions
		}
	}
})
</script>
