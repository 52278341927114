<template>
	<div>
		<div class="md:grid grid-cols-2">
			<div>
				<h1 class="border-b-2 border-slate-200 pb-1 flex items-end space-x-2 justify-between">
					<p class="text-xl font-bold">Your wallet</p>
					<!-- <div class="flex-grow-0 text-center"><jazzicon :address="metamask.state.wallet" :seed="7" :diameter="30" /></div> -->
				</h1>
				<div class="space-y-3 mt-4">
					<p class="flex items-end space-x-4 border-b border-slate-200 pb-2">
						<span class="flex-grow font-bold">Address</span>
						<span class="truncate" :title="metamask.state.wallet">{{  metamask.state.wallet }}</span>
					</p>
					<p class="flex mt-2 border-b border-slate-200 pb-2">
						<span class="flex-grow font-bold">$BPX Balance</span>
						<span>
							<i class="text-slate-400 fa-solid fa-wallet"></i>
							{{ $format(walletStore.currentBalance.toDecimal()) }}
							{{ appStore.tokenSymbol }}
						</span>
					</p>
				</div>
			</div>
			<div class="md:p-4 grid grid-cols-3 md:grid-cols-2 lg:grid-cols-3 gap-4 text-center mt-4">
				<div class="md:hidden lg:block bg-white rounded-xl p-6">
					<p class="text-lg lg:text-3xl text-slate-600">
						{{ $format(profileStore.watchedAuctions.length) }}
					</p>
					<p class="text-sm lg:text-base text-slate-500">Watching</p>
				</div>
				<div class="bg-white rounded-xl p-6">
					<p class="text-lg lg:text-3xl text-slate-600">
						{{ $format(profileStore.activeBids.length) }}
					</p>
					<p class="text-sm lg:text-base text-slate-500">Bids</p>
				</div>
				<div class="bg-white rounded-xl p-6">
					<p class="text-lg lg:text-3xl text-slate-600">
						{{ $format(profileStore.winningAuctions.length) }}
					</p>
					<p class="text-sm lg:text-base text-slate-500">Winning</p>
				</div>
			</div>
		</div>

	<div class="mt-6">
		<h1 class="border-b-2 border-slate-200 pb-1 flex items-end space-x-2 justify-start">
			<p class="text-xl font-bold">Watchlist Activity</p>
		</h1>

		<div class="space-y-8 mt-4">
			<watchlist-item
				v-if="sortedWatchlist.length"
				v-for="auction in sortedWatchlist"
				:key="`watchlist-auction-${auction.id}`"

				:auction="auction"
			/>
			<!-- <watchlist-item
				v-if="profileStore.watchedAuctions.length"
				v-for="auction in profileStore.watchedAuctions"
				:key="`watchlist-auction-${auction.id}`"

				:auction="auction"
			/> -->
			<p v-else
				class="text-center text-slate-500 text-lg my-4">
				You're not watching any active auctions
			</p>
		</div>
	</div>

	<div class="mt-6">
		<h1 class="border-b-2 border-slate-200 pb-1 flex items-end space-x-2 justify-start">
			<p class="text-xl font-bold">Claimable</p>
			<p v-if="profileStore.claimableAuctions.length">
				<span class="inline-block bg-red-500 text-white rounded-full px-2 py-1 text-xs">
					{{ profileStore.claimableAuctions.length }}
				</span>
			</p>
		</h1>

		<div class="space-y-4 mt-4">
			<won-auction-listing
				v-if="profileStore.claimableAuctions.length"
				v-for="auction in profileStore.claimableAuctions.slice(0, 3)"
				:key="`claimable-auction-${auction.id}`"
				:auction="auction"
			/>
			<p v-else class="text-center text-slate-500 text-lg my-4">
				No auctions pending claim
			</p>
		</div>
	</div>

	<div class="mt-6">
		<h1 class="border-b-2 border-slate-200 pb-1 flex items-end space-x-2 justify-start">
			<p class="text-xl font-bold">Sold</p>
			<p v-if="profileStore.soldAuctions.length">
				<span class="inline-block bg-red-500 text-white rounded-full px-2 py-1 text-xs">
					{{ profileStore.soldAuctions.length }}
				</span>
			</p>
		</h1>

		<div class="space-y-6 mt-4">
			<sold-auction-listing
				class="border-b-2 border-slate-200 pb-6"
				v-if="profileStore.soldAuctions.length"
				v-for="auction in profileStore.soldAuctions.slice(0, 3)"
				:key="`selling-auction-${auction.id}`"
				:auction="auction"
			/>
			<p v-else class="text-center text-slate-500 text-lg my-4">
				No auctions pending claim
			</p>
		</div>
	</div>
</div>
</template>
<script lang="ts">
import { defineComponent, PropType, onMounted, computed } from "vue";
import AuctionListingCondensed from "@/components/AuctionListingCondensed.vue";
import WonAuctionListing from "@/components/WonAuctionListing.vue";
import SoldAuctionListing from "@/components/SoldAuctionListing.vue";
import BidHistoryItem from "@/components/BidHistoryItem.vue";
import WatchlistBid from "@/components/WatchlistBid.vue";
import WatchlistItem from "@/components/WatchlistItem.vue";
import { useAuctionStore } from "@/stores/AuctionStore";
import { useAuthStore, AuthToken } from "@/stores/AuthStore";
import { useProfileStore } from '@/stores/ProfileStore'
import metamask from '@/util/metamask'
import Currency from "@/types/Currency";
import { useAppStore } from "@/stores/AppStore";
import { useWalletStore } from "@/stores/WalletStore";
import { Auction, AuctionGroup, StatusEnded } from "@/types/Auction";
import { DateTime } from "ts-luxon";

export default defineComponent({
    components: { AuctionListingCondensed, SoldAuctionListing, WonAuctionListing, WatchlistBid, WatchlistItem },
	setup(props) {
		const appStore = useAppStore()
		const auctionStore = useAuctionStore();
		const authStore = useAuthStore();
		const profileStore = useProfileStore();
		const walletStore = useWalletStore()

		onMounted(() => {
			auctionStore.fetchAuctions();
		})

		const sortedWatchlist = computed(() => {
			return profileStore.watchedAuctions.filter(a => a.bids.length > 0)
				.sort(
				(a1, a2) => {
					if (a1.end_at == a2.end_at) {
						if (a1.bids.length == a2.bids.length) {
							return 0
						}

						return a2.bids.length - a1.bids.length
					}

					if (a1.end_at < a2.end_at) {
						return -1
					}

					return 1
				}
			)
		})

		const watchedAuctions = computed(() => {
			if ( ! profileStore.watched || 0 == profileStore.watched.length) {
				return []
			}

			return profileStore.watched;
		})

		const bids = computed(() => {
			if ( ! profileStore.bids || 0 == profileStore.bids.length) {
				return []
			}

			return profileStore.bids
		})

		const winningAuctions = computed(() => {
			if ( ! walletStore.wallet) {
				return []
			}

			if ( ! auctionStore.auctions || auctionStore.auctions.length == 0) {
				return []
			}

			return auctionStore.auctions.filter(
				(a: Auction) => {
					return a.high_bidder && a.high_bidder?.toLowerCase() == walletStore.wallet.toLowerCase()
				}
			)
		})

		function auctionGroup(auctionId: number) : AuctionGroup | null {
			return auctionStore.auctions?.find(a => a.id == auctionId).auction_group ?? null
		}

		const auctionGroupsByAuction = computed(() => {
			if ( ! auctionStore.auctions || auctionStore.auctions.length == 0) {
				return {}
			}

			return auctionStore.auctions.reduce((all, auction) => {
				all[ auction.id ] = auction.auction_group
				return all
			}, {})
		});

		// function auctionGroup(auctionId: number) {
		// 	return auctionStore.fetchAuctions().then((auctions) => {
		// 		return auctions.filter(
		// 			a => a.id == auctionId
		// 		)[0].auction_group || null
		// 	})
		// }

		return {
			Currency,
			appStore,
			auctionStore,
			authStore,
			walletStore,
			profileStore,
			metamask,

			sortedWatchlist,
			bids,
			winningAuctions,
			auctionGroup,
			auctionGroupsByAuction,
		}
	}
})
</script>
