<template>
	<div class="flex-grow">
		<div
			class="bg-[url('/image/hero-bg.jpeg')] bg-cover h-1/2 text-center flex flex-col justify-center items-center py-12 sm:py-24 md:py-44 lg:py-60"
		>
			<h1 class="text-4xl lg:text-5xl font-semibold">
				Welcome to BPX Auctions
			</h1>
			<h2 class="text-xl lg:text-2xl mt-2">
				Unlock the Door to an Exclusive World of Collectibles!
			</h2>
			<p class="mt-6">
				<a href="#auctions" class="btn-primary-lg"
					>Join our Auctions Now!</a
				>
			</p>
		</div>
		<div v-if="!loaded" class="grid place-items-center my-12 lg:my-24">
			<div class="text-xl text-slate-400">Loading...</div>
		</div>
		<div
			v-else-if="events && events.length"
			id="auctions"
			class="grid gap-6 md:gap-12 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 my-6 justify-center md:mt-12 max-w-screen-xl mx-auto px-6 2xl:px-0"
		>
			<auction-group-item
				v-for="event in events"
				:key="`ag-${event.id}`"
				:event="event"
			/>
		</div>
		<div v-else class="grid place-items-center my-12 lg:my-24">
			<div class="text-xl text-slate-400">
				There are no active auctions right now.
			</div>
		</div>
	</div>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue'
import api from '@/util/api'
import { cdnURL } from '@/util/cdn'
import Countdown from '@/components/Countdown.vue'
import type { Event } from '@/types/Event'
import { DateTime } from 'ts-luxon'
import { countdown } from '@/util/countdown'
import { keyDuration } from '@/util/timeRemainingLabel'
import { useAuctionStore } from '@/stores/AuctionStore'
import { StatusExtended } from '@/types/Auction'
import { useAuctionStatusStore } from '@/stores/AuctionStatusStore'
import AuctionGroupItem from '@/components/AuctionGroupItem.vue'

export default defineComponent({
	components: { Countdown, AuctionGroupItem, },
	setup(props) {
		const events = ref<Event[]>([])
		const loaded = ref<boolean>(false)
		const now = ref(DateTime.now())
		const auctionStore = useAuctionStore()
		const auctionStatusStore = useAuctionStatusStore()
		let intvl

		onMounted(async () => {
			const e = await api.fetchEvents()
			auctionStore.fetchAuctions()
			events.value = e
			loaded.value = true

			intvl = setInterval(() => now.value = DateTime.now(), 100)
		})

		onUnmounted(() => {
			clearInterval(intvl)
		})

		const formatCountdown = function (duration: Duration): string {
			if (!duration) return ''
			const keyedDuration = keyDuration(duration)

			let unitGroups: string[][] = []

			unitGroups.push(['Days', 'Hours'])
			unitGroups.push(['Hours', 'Minutes'])
			unitGroups.push(['Minutes', 'Seconds'])
			unitGroups.push(['Seconds'])

			for (const units of unitGroups) {
				let output = []
				for (const unit of units) {
					const uKey = unit.toLowerCase()
					if ( undefined == keyedDuration[ uKey ]) {
						continue
					}

					if (keyedDuration[uKey].val > 0 || uKey == 'seconds') {
						output.push(`${keyedDuration[uKey].val} ${keyedDuration[uKey].label}`)
					}
				}

				if (output.length == units.length) {
					return output.join(', ')
				}
			}
		}

		const eventsFinished = computed(() => {
			return events.value.reduce((all, ev) => {
				const { finished } = countdown(ev.end_at)
				all[ ev.id ] = finished.value
				return all
			}, {})
		})

		const inExtendedBidding = computed(() => {
			if ( ! auctionStore.auctions) return {}

			return auctionStore.auctions.filter(a => StatusExtended == auctionStatusStore.status(a.id))
				.reduce((events, a) => {
					if (undefined == events[ a.auction_group.id ]) {
						events[ a.auction_group.id ] = 0
					}

					events[ a.auction_group.id ] ++
					return events
				}, {})
		})

		return {
			now,
			loaded,
			events,
			formatCountdown,
			eventsFinished,
			inExtendedBidding,
		}
	},
})
</script>
