<template>
	<div class="fixed
		group
		bottom-0 left-0 right-0 z-20
		sm:right-auto sm:max-w-[24rem]
		mx-4 mb-4 px-4 pt-4 pb-2
		bg-red-200
		rounded-xl shadow-lg shadow-gray-400"
		@click.stop="$emit('click', auction)">
		<div class="absolute z-30 hidden group-hover:flex justify-center items-center inset-0 bg-gray-900/20 rounded-xl cursor-pointer">

		</div>
		<div class="absolute -right-2 -top-2 rounded-full bg-white z-40">
			<button
				@click.stop="$emit('close')"
			><i class="fa-xl fa fa-circle-xmark text-red-600"></i></button>
		</div>
		<div class="grid grid-cols-[3fr,1fr] gap-4">
			<div class="text-gray-600">
				<p class="font-bold text-red-600">
					<i class="fa text-xl fa-triangle-exclamation text-red-600"></i>
					You were outbid!
				</p>
				<p class="text-gray-900 font-medium">
					{{ auction.title }}
				</p>
				<p class="">New Bid</p>
				<!-- <p class="mt-2">New high bid</p> -->
				<p class="text-2xl font-bold text-gray-900">
					{{ $format(currentBid.toDecimal()) }}
					{{ appStore.tokenSymbol }}
				</p>
				<p class="text-sm">
					{{
						$format(
							currentBid
								.addPercentage(
									auction.buyer_premium_bps
								)
								.toDecimal()
						)
					}}
					{{ appStore.tokenSymbol }} w/buyer's premium
				</p>
				<p class="text-sm">by <wallet-address :address="auction.high_bidder" /></p>
				</div>
			<div>
				<img :src="$cdn(auction.images[0].uri)" class="rounded-xl border-2 border-red-300">
			</div>
		</div>
		<!-- <p class="sm:hidden text-center text-xs text-gray-500">Touch to dismiss</p> -->
		<!-- <p class="hidden sm:block text-center text-gray-500 text-xs"><button>Dismiss</button></p> -->
	</div>
</template>
<script lang="ts">
import { useAppStore } from "@/stores/AppStore";
import Currency from "@/types/Currency";
import { computed, defineComponent, PropType, ref } from "vue";
import { Notification } from "@/stores/NotificationStore";

export default defineComponent({
	props: {
		notification: {
			type: Object,
			required: true
		}
	},
	emits: ['close'],
	setup(props) {
		const appStore = useAppStore()
		const currentBid = computed(() => {
			return new Currency(auction.value.high_bid)
		})

		const auction = computed(() => {
			return props.notification.auction
		})

		return {
			props,
			appStore,
			currentBid,
			auction,
		}
	}
})
</script>
