<template>
	<div class="text-center">
		<div v-if="countState != Target.CountFromEnd">
			<p class="leading-5 font-medium">
				<span class="text-2xl font-semibold">{{
					formatCountdown()
				}}</span>
				<br>
				<span class="font-normal">
					<span v-if="auctionStatus == StatusClosed">
						Until auction opens
					</span>
					<span v-else-if="auctionStatus == StatusOpen">
						Until extended bidding
					</span>
					<span v-else-if="auctionStatus == StatusExtended">
						Until auction ends
					</span>
				</span>
			</p>
			<p class="text-slate-500 mt-2 text-xs">
				<span v-if="countState == Target.CountToStart">
					Auction opens
					<br />
					{{ props.auction.start_at.toFormat('EEE, ff ZZZZ') }}
				</span>
				<span v-else-if="countState == Target.CountToExtended">
					Extended bidding begins
					<br />
					{{ props.auction.end_at.toFormat('EEE, ff ZZZZ') }}
				</span>
				<span v-else-if="countState == Target.CountToEnd">
					Auction ends
					<br />
					{{ props.auction.end_at.toFormat('EEE, ff ZZZZ') }}
				</span>
			</p>
		</div>
		<div v-else>
			This auction has ended
		</div>
	</div>
</template>
<script lang="ts">
import { Duration, DateTime } from 'ts-luxon'
import Countdown from '@/components/Countdown.vue'
import { Auction, StatusCancelled, StatusClosed, StatusDoesNotExist, StatusEnded, StatusExtended, StatusOpen } from '@/types/Auction'
import { useAuctionStatusStore } from '@/stores/AuctionStatusStore'
import { countdown, Target } from '@/util/countdownAuction'
import { computed, onUnmounted } from 'vue'
import { keyDuration } from '@/util/timeRemainingLabel'

export default {
	components: { Countdown },
	props: {
		auction: {
			required: true,
		},
	},
	setup(props) {
		const auctionStatusStore = useAuctionStatusStore()

		const { duration, countState, destruct} = countdown(
			props.auction, auctionStatusStore
		)

		onUnmounted(() => {
			destruct()
		})

		const auctionStatus = computed(() => {
			return auctionStatusStore.status( props.auction.id )
		})

		const formatCountdown = function(): string {
			if (!duration) return ''
			const keyedDuration = keyDuration(duration.value)

			let unitGroups: string[][] = []

			unitGroups.push(['Days', 'Hours'])
			unitGroups.push(['Hours', 'Minutes'])
			unitGroups.push(['Minutes', 'Seconds'])
			unitGroups.push(['Seconds'])

			for (const units of unitGroups) {
				let output = []
				for (const unit of units) {
					const uKey = unit.toLowerCase()
					if ( undefined == keyedDuration[ uKey ]) {
						continue
					}

					if (keyedDuration[uKey].val > 0 || uKey == 'seconds') {
						output.push(`${keyedDuration[uKey].val} ${keyedDuration[uKey].label}`)
					}
				}

				if (output.length == units.length) {
					return output.join(', ')
				}
			}
		}

		return {
			props,
			duration,
			auctionStatus,
			formatCountdown,
			countState,
			Target,
			StatusCancelled, StatusClosed, StatusDoesNotExist, StatusEnded, StatusExtended, StatusOpen
		}
	},
}
// auction closed -> open
// auction open -> extended
// auction extended -> ended
// auction is closed, and not opening
</script>
