<template>
	<div class="z-20 fixed inset-0 bg-black bg-opacity-60 flex justify-center items-start filter drop-shadow-lg overflow-y-scroll">
	  <div class="z-20 absolute inset-0 bg-transparent" @click.self="handleAwayClick"></div>

	  <div class="z-30 w-full mx-0 px-2 py-0 max-w-screen-sm bg-brown-900 rounded my-12 shadow-lg">

		<slot name="default" :confirm="confirm" :cancel="cancel" :close="close" />

	  </div>
	</div>
</template>
<script lang="ts">
import { defineComponent, PropType, onMounted, onUnmounted } from "vue";

export default defineComponent({
	props: {
		closeOnClickAway: {
			type: Boolean,
			default: false,
			required: false,
		},
	},
	emits: ['close', 'cancel', 'confirm'],

	setup(props, { emit: $emit }) {

		onMounted(() => {
		document.body.classList.add('overflow-hidden');
		});

		onUnmounted(() => {
		document.body.classList.remove('overflow-hidden');
		});

		let close = () => {
		$emit('close');
		}

		const confirm = (evt: any) => {
			$emit('confirm', evt);
		}

		const cancel = (evt: any) => {
			$emit('cancel', evt);
		}

		const handleAwayClick = (evt: any) => {
			if (props.closeOnClickAway) {
				close();
			}
		}

		return {
		props,
		close,
		confirm,
		cancel,
		handleAwayClick
		}
	}
})
</script>
