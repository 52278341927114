<template>
	<div>
		<h1 class="border-b-2 border-slate-200 pb-1 flex items-end space-x-2 justify-between">
			<p class="text-xl font-bold">Sold Auctions</p>
		</h1>

		<div class="space-y-8 mt-4">
			<p v-if="auctions.length == 0"
				class="text-slate-500 text-lg text-center">
				You haven't consigned any items yet
			</p>
			<sold-auction-listing
				class="border-b-2 border-slate-200 pb-6"
				v-else
				v-for="auction in auctions"
				:key="`auction-${auction.id}`"
				:auction="auction"
			/>
		</div>

		<claim-proceeds-modal
			v-if="state.claimAuction"
			@close="state.claimAuction = null"
			:auction="state.claimAuction"
			:key="`auction-claim-${state.claimAuction? state.claimAuction.id : 'null'}`"
		/>
	</div>
</template>
<script lang="ts">
import { computed, defineComponent, PropType, onMounted, reactive, ref } from "vue";
import AuctionList from "@/pages/Account/AuctionList.vue";
// import AuctionListingCondensed from "@/components/AuctionListingCondensed.vue";
import SoldAuctionListing from "@/components/SoldAuctionListing.vue";
import ClaimProceedsModal from '@/components/ClaimProceedsModal.vue'
import { useProfileStore } from "@/stores/ProfileStore";
import { useAuthStore } from "@/stores/AuthStore";
import { useAppStore } from "@/stores/AppStore";
import { Auction } from "@/types/Auction";
import { DateTime } from "ts-luxon";
import Currency from "@/types/Currency";

export default defineComponent({
	components: { AuctionList, ClaimProceedsModal, SoldAuctionListing },
	setup(props) {
		const profileStore = useProfileStore()
		const authStore = useAuthStore()
		const appStore = useAppStore()
		const now = ref(DateTime.now())

		let timerInterval

		const state = reactive({
			claimAuction: null as Auction | null,
		})

		onMounted(async() => {
			const t = await authStore.authToken()
			profileStore.loadAccount(
				t
			);

			timerInterval = setInterval(() => {
				now.value = DateTime.now()
			}, 1000)
		})

		const auctions = computed(() => {
			return [
				...profileStore.soldAuctions,
				...profileStore.allSoldAuctions,
			]
		});


		function onClaim(auction) {
			state.claimAuction = auction
		}

		const canClaimAuctions = computed(() => {
			return auctions.value.reduce((all, auction) => {
				all[ auction.id ] = auction.proceeds_claimed_at == null
					&& now.value > auction.auction_group.end_at.plus( auction.extended_bidding_seconds )
				return all;
			}, {})
		})

		return {
			Currency,
			state,
			auctions,
			appStore,
			onClaim,
			now,
			canClaimAuctions,
		}
	}
})
</script>
