<template>
	<div>
		<auction-breadcrumbs
			class="hidden md:block px-4"
			:group="
				auctionStore.auction ? auctionStore.auction.auction_group : null
			"
			:auction="auctionStore.auction"
		/>

		<auction-loading v-if="!state.loaded" />
		<div v-else>
			<div class="md:grid md:grid-cols-[2fr,3fr]">
				<div
					class="overflow-hidden p-6 bg-white md:bg-transparent"
					id="auction-image"
				>
					<auction-image-carousel
						:images="
							auctionStore.auction
								? auctionStore.auction.images
								: []
						"
					/>
				</div>
				<div>
					<auction-breadcrumbs
						class="md:hidden"
						:group="auctionStore.auction.auction_group"
						:auction="auctionStore.auction"
					/>

					<sticky-auction-banner
						v-if="state.showStickyBanner"
						:auction="auctionStore.auction"
						@bid="state.showBidModal = true"
					/>

					<h2 class="font-bold text-xl mt-3 mx-4" ref="auctionTitle">
						{{ auctionStore.auction.title }}
					</h2>
					<div class="space-y-2 md:space-y-0 md:flex md:space-x-4 mx-4">
						<time-remaining :auction="auctionStore.auction" />
						<bidder-flag
							v-if="auctionStore.auction.high_bidder"
							:auction="auctionStore.auction"
						/>
					</div>
					<!-- <auction-countdown class="mx-4"
					:auction="auctionStore.auction"
				/> -->
					<div class="mt-2 rounded">
						<div
							class="bg-white mx-4 rounded-xl px-4 py-4 drop-shadow"
						>
							<div v-if="auctionStore.auction.bid_count > 0">
								<p>Current Bid</p>
								<p class="font-semibold text-2xl">
									{{ $format(currentBid.toDecimal()) }}
									{{ appStore.tokenSymbol }}
								</p>
								<p class="text-xs text-slate-500">
									w/Buyer's Premium:
									{{
										$format(
											currentBid
												.addPercentage(
													auctionStore.auction
														.buyer_premium_bps
												)
												.toDecimal()
										)
									}}
									{{ appStore.tokenSymbol }}
								</p>

								<div class="text-sm text-slate-500 mt-4">
									<p class="font-medium">
										Minimum bid:
										{{ $format(minimumBid.toDecimal()) }}
										{{ appStore.tokenSymbol }}
									</p>
									<p class="text-xs mt-1">
										w/Buyer's Premium:
										{{
											$format(
												minimumBid
													.addPercentage(
														auctionStore.auction
															.buyer_premium_bps
													)
													.toDecimal()
											)
										}}
										{{ appStore.tokenSymbol }}
									</p>
								</div>
							</div>
							<div v-else>
								<p>Starting bid</p>
								<p class="font-semibold text-2xl">
									{{ $format(minimumBid.toDecimal()) }}
									{{ appStore.tokenSymbol }}
								</p>
								<p class="text-xs text-slate-500">
									w/Buyer's Premium:
									{{
										$format(
											minimumBid
												.addPercentage(
													auctionStore.auction
														.buyer_premium_bps
												)
												.toDecimal()
										)
									}}
									{{ appStore.tokenSymbol }}
								</p>
							</div>
						</div>
					</div>

					<div class="mx-4 mt-6" ref="customBid" v-if="auctionOpen">
						<div class="flex items-center space-x-2">
							<div
								class="flex-shrink flex-grow flex items-center bg-white drop-shadow rounded-xl overflow-hidden space-x-2"
							>
								<input
									v-model="state.bid"
									type="number"
									max="5000000000"
									@focus.stop="
										$event.target.select() || false
									"
									@keyup="keyupValidator"
									@change="keyupValidator"
									class="border-0 flex-auto text-xl p-2 outline-none focus:ring-0 text-right w-full rounded-lg"
								/>
								<p class="text-gray-400 pr-4">
									{{ appStore.tokenSymbol }}
								</p>
							</div>

							<div>
								<button
									class="block w-full btn-primary-lg whitespace-nowrap"
									@click.stop="handleBidClicked"
								>
									Bid Now
								</button>
							</div>
						</div>

						<p v-if="state.error" class="mt-1 text-xs text-red-500">
							{{ state.error }}
						</p>
						<p class="mt-1 text-center text-xs text-slate-500">
							Note: All bids placed are spot bids.
						</p>
					</div>

					<auction-countdown
						class="mx-4 py-4"
						:auction="auctionStore.auction"
					/>

					<div v-show="auctionOpen"
						class="bg-gradient-to-tr from-uniswap-pink to-purple-500 text-white p-4 rounded-xl shadow-lg mx-4 sm:mx-auto grid grid-cols-[auto,1fr] items-center max-w-md lg:max-w-lg"
					>
						<svg
							viewBox="0 0 641 640"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							class="w-20 lg:w-28"
						>
							<path
								d="M224.534 123.226C218.692 122.32 218.445 122.213 221.195 121.791C226.464 120.98 238.905 122.085 247.479 124.123C267.494 128.881 285.707 141.069 305.148 162.714L310.313 168.465L317.701 167.277C348.828 162.275 380.493 166.25 406.978 178.485C414.264 181.851 425.752 188.552 427.187 190.274C427.645 190.822 428.485 194.355 429.053 198.124C431.02 211.164 430.036 221.16 426.047 228.625C423.877 232.688 423.756 233.975 425.215 237.452C426.38 240.227 429.627 242.28 432.843 242.276C439.425 242.267 446.509 231.627 449.791 216.823L451.095 210.943L453.678 213.868C467.846 229.92 478.974 251.811 480.885 267.393L481.383 271.455L479.002 267.762C474.903 261.407 470.785 257.08 465.512 253.591C456.006 247.301 445.955 245.161 419.337 243.758C395.296 242.491 381.69 240.438 368.198 236.038C345.244 228.554 333.672 218.587 306.405 182.812C294.294 166.923 286.808 158.131 279.362 151.051C262.442 134.964 245.816 126.527 224.534 123.226Z"
								fill="currentColor"
							/>
							<path
								d="M432.61 158.704C433.215 148.057 434.659 141.033 437.562 134.62C438.711 132.081 439.788 130.003 439.954 130.003C440.12 130.003 439.621 131.877 438.844 134.167C436.733 140.392 436.387 148.905 437.84 158.811C439.686 171.379 440.735 173.192 454.019 186.769C460.25 193.137 467.497 201.168 470.124 204.616L474.901 210.886L470.124 206.405C464.282 200.926 450.847 190.24 447.879 188.712C445.89 187.688 445.594 187.705 444.366 188.927C443.235 190.053 442.997 191.744 442.84 199.741C442.596 212.204 440.897 220.204 436.797 228.203C434.58 232.529 434.23 231.606 436.237 226.723C437.735 223.077 437.887 221.474 437.876 209.408C437.853 185.167 434.975 179.339 418.097 169.355C413.821 166.826 406.776 163.178 402.442 161.249C398.107 159.32 394.664 157.639 394.789 157.514C395.267 157.038 411.727 161.842 418.352 164.39C428.206 168.181 429.833 168.672 431.03 168.215C431.832 167.909 432.22 165.572 432.61 158.704Z"
								fill="currentColor"
							/>
							<path
								d="M235.883 200.175C224.022 183.846 216.684 158.809 218.272 140.093L218.764 134.301L221.463 134.794C226.534 135.719 235.275 138.973 239.369 141.459C250.602 148.281 255.465 157.263 260.413 180.328C261.862 187.083 263.763 194.728 264.638 197.317C266.047 201.483 271.369 211.214 275.696 217.534C278.813 222.085 276.743 224.242 269.853 223.62C259.331 222.67 245.078 212.834 235.883 200.175Z"
								fill="currentColor"
							/>
							<path
								d="M418.223 321.707C362.793 299.389 343.271 280.017 343.271 247.331C343.271 242.521 343.437 238.585 343.638 238.585C343.84 238.585 345.985 240.173 348.404 242.113C359.644 251.128 372.231 254.979 407.076 260.062C427.58 263.054 439.119 265.47 449.763 269C483.595 280.22 504.527 302.99 509.518 334.004C510.969 343.016 510.118 359.915 507.766 368.822C505.91 375.857 500.245 388.537 498.742 389.023C498.325 389.158 497.917 387.562 497.81 385.389C497.24 373.744 491.355 362.406 481.472 353.913C470.235 344.257 455.137 336.569 418.223 321.707Z"
								fill="currentColor"
							/>
							<path
								d="M379.31 330.978C378.615 326.846 377.411 321.568 376.633 319.25L375.219 315.036L377.846 317.985C381.481 322.065 384.354 327.287 386.789 334.241C388.647 339.549 388.856 341.127 388.842 349.753C388.828 358.221 388.596 359.996 386.88 364.773C384.174 372.307 380.816 377.649 375.181 383.383C365.056 393.688 352.038 399.393 333.253 401.76C329.987 402.171 320.47 402.864 312.103 403.299C291.016 404.395 277.138 406.661 264.668 411.04C262.875 411.67 261.274 412.052 261.112 411.89C260.607 411.388 269.098 406.326 276.111 402.948C285.999 398.185 295.842 395.586 317.897 391.913C328.792 390.098 340.043 387.897 342.9 387.021C369.88 378.749 383.748 357.402 379.31 330.978Z"
								fill="currentColor"
							/>
							<path
								d="M404.719 376.105C397.355 360.273 395.664 344.988 399.698 330.732C400.13 329.209 400.824 327.962 401.242 327.962C401.659 327.962 403.397 328.902 405.103 330.05C408.497 332.335 415.303 336.182 433.437 346.069C456.065 358.406 468.966 367.959 477.74 378.873C485.423 388.432 490.178 399.318 492.467 412.593C493.762 420.113 493.003 438.206 491.074 445.778C484.99 469.653 470.85 488.406 450.682 499.349C447.727 500.952 445.075 502.269 444.788 502.275C444.501 502.28 445.577 499.543 447.18 496.191C453.965 482.009 454.737 468.214 449.608 452.859C446.467 443.457 440.064 431.985 427.135 412.596C412.103 390.054 408.417 384.054 404.719 376.105Z"
								fill="currentColor"
							/>
							<path
								d="M196.519 461.525C217.089 444.157 242.682 431.819 265.996 428.032C276.043 426.399 292.78 427.047 302.084 429.428C316.998 433.245 330.338 441.793 337.276 451.978C344.057 461.932 346.966 470.606 349.995 489.906C351.189 497.519 352.489 505.164 352.882 506.895C355.156 516.897 359.583 524.892 365.067 528.907C373.779 535.283 388.78 535.68 403.536 529.924C406.041 528.947 408.215 528.271 408.368 528.424C408.903 528.955 401.473 533.93 396.23 536.548C389.177 540.071 383.568 541.434 376.115 541.434C362.6 541.434 351.379 534.558 342.016 520.539C340.174 517.78 336.032 509.516 332.813 502.176C322.928 479.628 318.046 472.759 306.568 465.242C296.579 458.701 283.697 457.53 274.006 462.282C261.276 468.523 257.724 484.791 266.842 495.101C270.465 499.198 277.223 502.732 282.749 503.419C293.086 504.705 301.97 496.841 301.97 486.404C301.97 479.627 299.365 475.76 292.808 472.801C283.852 468.76 274.226 473.483 274.272 481.897C274.292 485.484 275.854 487.737 279.45 489.364C281.757 490.408 281.811 490.491 279.929 490.1C271.712 488.396 269.787 478.49 276.394 471.913C284.326 464.018 300.729 467.502 306.362 478.279C308.728 482.805 309.003 491.82 306.94 497.264C302.322 509.448 288.859 515.855 275.201 512.368C265.903 509.994 262.117 507.424 250.906 495.876C231.425 475.809 223.862 471.92 195.777 467.536L190.395 466.696L196.519 461.525Z"
								fill="currentColor"
							/>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M49.6202 12.0031C114.678 90.9638 214.977 213.901 219.957 220.784C224.068 226.467 222.521 231.576 215.478 235.58C211.561 237.807 203.508 240.063 199.476 240.063C194.916 240.063 189.779 237.867 186.038 234.318C183.393 231.81 172.721 215.874 148.084 177.646C129.233 148.396 113.457 124.131 113.027 123.725C112.032 122.785 112.049 122.817 146.162 183.854C167.582 222.181 174.813 235.731 174.813 237.543C174.813 241.229 173.808 243.166 169.261 248.238C161.681 256.694 158.293 266.195 155.847 285.859C153.104 307.902 145.394 323.473 124.026 350.122C111.518 365.722 109.471 368.581 106.315 374.869C102.339 382.786 101.246 387.221 100.803 397.219C100.335 407.79 101.247 414.619 104.477 424.726C107.304 433.575 110.255 439.417 117.8 451.104C124.311 461.188 128.061 468.683 128.061 471.614C128.061 473.947 128.506 473.95 138.596 471.672C162.741 466.219 182.348 456.629 193.375 444.877C200.199 437.603 201.801 433.586 201.853 423.618C201.887 417.098 201.658 415.733 199.896 411.982C197.027 405.877 191.804 400.801 180.292 392.932C165.209 382.621 158.767 374.32 156.987 362.904C155.527 353.537 157.221 346.928 165.565 329.44C174.202 311.338 176.342 303.624 177.79 285.378C178.725 273.589 180.02 268.94 183.407 265.209C186.939 261.317 190.119 260 198.861 258.805C213.113 256.858 222.188 253.171 229.648 246.297C236.119 240.334 238.827 234.588 239.243 225.938L239.558 219.382L235.942 215.166C222.846 199.896 40.85 0 40.044 0C39.8719 0 44.1813 5.40178 49.6202 12.0031ZM135.412 409.18C138.373 403.937 136.8 397.195 131.847 393.902C127.167 390.79 119.897 392.256 119.897 396.311C119.897 397.548 120.582 398.449 122.124 399.243C124.72 400.579 124.909 402.081 122.866 405.152C120.797 408.262 120.964 410.996 123.337 412.854C127.162 415.849 132.576 414.202 135.412 409.18Z"
								fill="currentColor"
							/>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M248.552 262.244C241.862 264.299 235.358 271.39 233.344 278.826C232.116 283.362 232.813 291.319 234.653 293.776C237.625 297.745 240.499 298.791 248.282 298.736C263.518 298.63 276.764 292.095 278.304 283.925C279.567 277.229 273.749 267.948 265.736 263.874C261.601 261.772 252.807 260.938 248.552 262.244ZM266.364 276.172C268.714 272.834 267.686 269.225 263.69 266.785C256.08 262.138 244.571 265.983 244.571 273.173C244.571 276.752 250.572 280.656 256.074 280.656C259.735 280.656 264.746 278.473 266.364 276.172Z"
								fill="currentColor"
							/>
						</svg>

						<div
							class="text-sm lg:text-base space-y-2 lg:space-y-4"
						>
							<div
								class="text-lg lg:text-xl font-poppins font-semibold"
							>
								Need more $BPX?
							</div>
							<div>
								$BPX is not sold directly but can be securely
								acquired via Uniswap. Click below to head there.
							</div>
							<div>
								<a
									href="https://app.uniswap.org/#/tokens/ethereum/0x313408eb31939a9c33b40afe28dc378845d0992f"
									target="_blank"
									rel="nofollow noreferrer"
									class="block text-center py-2 border-2 border-white/80 rounded-lg font-bold bg-white/80 hover:border-white/100 active:border-white/100 hover:bg-white/100 active:bg-white/100 transition-all"
								>
									<span
										class="text-transparent bg-clip-text bg-gradient-to-tr from-uniswap-pink to-purple-500 font-poppins font-semibold"
										>Swap for more $BPX</span
									>
								</a>
							</div>
						</div>
					</div>

					<div class="my-6 mx-4">
						<h2
							class="text-lg font-bold mb-2 border-b-2 border-slate-200"
						>
							Description
						</h2>
						<div
							class="markdown space-y-2"
							v-html="auctionStore.auction.md_description"
						></div>
					</div>

					<div class="space-x-2 mx-2 my-4">
						<span class="text-xs font-semibold text-slate-600"
							>Categories</span
						>
						<span
							v-for="tag in auctionStore.auction.tags"
							class="text-xs inline-block px-2 py-.5 bg-slate-200 text-slate-500 rounded-lg"
						>
							{{ tag.name }}
						</span>
					</div>

					<div class="my-8 mx-2">
						<h2
							id="bid-history"
							class="text-lg font-bold mb-2 border-b-2 border-slate-200"
						>
							Bid History
						</h2>
						<auction-bid-history
							:bids="auctionStore.auction.bids"
						/>
					</div>
				</div>
			</div>

			<similar-auctions
				class="my-8"
				:auction="auctionStore.auction"
				:auctions="auctionStore.activeAuctions"
			/>

			<bid-preview
				v-if="state.showBidModal"
				:current-bid="state.bid"
				:auction="auctionStore.auction"
				@close="state.showBidModal = false"
			/>
			<error-modal
				class="z-50"
				v-if="!!state.error && state.showErrorModal"
				:component="GeneralErrorComponent"
				:error="state.error"
				@close="state.showErrorModal = false"
			/>
		</div>
	</div>
</template>
<script lang="ts">
import {
	computed,
	defineComponent,
	onMounted,
	onUnmounted,
	PropType,
	reactive,
	ref,
	watch,
} from 'vue'
import AuctionLoading from '@/components/AuctionLoading.vue'
import StickyAuctionBanner from '@/components/StickyAuctionBanner.vue'
import AuctionImageCarousel from '@/components/AuctionImageCarousel.vue'
import AuctionCountdown from '@/components/NewAuctionCountdown.vue'
import AuctionBidHistory from '@/components/AuctionBidHistory.vue'
import AuctionBreadcrumbs from '@/components/AuctionBreadcrumbs.vue'
import TimeRemaining from '@/components/TimeRemaining.vue'
import SimilarAuctions from '@/components/SimilarAuctions.vue'
import GeneralErrorComponent from '@/components/Errors/GeneralError.vue'
import BidderFlag from '@/components/BidderFlag.vue'
import BidPreview from '@/components/BidPreview.vue'
import { useRoute, useRouter } from 'vue-router'
import { useAuctionStore } from '@/stores/AuctionStore'
import { useAppStore } from '@/stores/AppStore'
import Currency from '@/types/Currency'
import AuctionTile from '@/components/AuctionTile.vue'
import MainNavigation from '../components/MainNavigation.vue'
import BN from 'bn.js'
import { useWalletStore } from '@/stores/WalletStore'
import { debounce } from '@/util/debounce'
import currencyFormat from '@/util/currencyFormat'
import ErrorModal from '@/components/Errors/ErrorModal.vue'
import Bugsnag from '@bugsnag/js'
import { DateTime } from 'ts-luxon'
import { useAuctionStatusStore } from '@/stores/AuctionStatusStore'
import { StatusExtended, StatusOpen } from '@/types/Auction'

export default defineComponent({
	components: {
		MainNavigation,
		AuctionBreadcrumbs,
		AuctionTile,
		AuctionLoading,
		StickyAuctionBanner,
		AuctionImageCarousel,
		AuctionCountdown,
		AuctionBidHistory,
		BidPreview,
		ErrorModal,
		BidderFlag,
		SimilarAuctions,
		TimeRemaining,
	},
	setup(props) {
		const route = useRoute()
		const router = useRouter()
		const auctionStore = useAuctionStore()
		const appStore = useAppStore()
		const walletStore = useWalletStore()
		const auctionStatusStore = useAuctionStatusStore()

		const scrollHeader = ref(null)
		// const scrollBidBtn = ref(null);
		const auctionTitle = ref(null)
		const customBid = ref(null)

		const observers = []

		const state = reactive({
			loaded: false,
			showStickyBanner: false,
			bid: null,
			showBidModal: false,
			error: null,
			showErrorModal: false,
		})

		onMounted(() => {
			loadAuction(route.params.auction)
		})

		onUnmounted(() => {
			clearObservers()
		})

		async function loadAuction(id: number): Promise {
			state.loaded = false
			return auctionStore.fetchAuction(id).then(() => {
				updateMinimumBid()
				state.loaded = true
				// setTimeout(attachObservers, 1)
			})
		}

		watch(
			() => {
				if (auctionStore.auction) {
					return auctionStore.auction.high_bid
				}

				return auctionStore.auction
			},
			() => {
				updateMinimumBid()
			}
		)

		const auctionOpen = computed(() => {
			return [ StatusOpen, StatusExtended ].indexOf(auctionStatusStore.status(auctionStore.auction.id)) >= 0
		})

		function validateBid(): boolean {
			state.error = null
			const currentBalance = new BN(walletStore.currentBalance.toString())

			const minBid = new BN(minimumBid.value.toString())

			const currentBid = new BN(
				Currency.fromDecimal(state.bid).toString()
			)

			if (currentBalance.lt(currentBid)) {
				// console.error('insufficient funds')
				state.error = `You do not have enough ${appStore.tokenSymbol}`
				return false
			}

			if (currentBid.lt(minBid)) {
				// console.error('too low')
				state.error = `Your bid must be more than ${currencyFormat(
					minimumBid.value.toDecimal()
				)} ${appStore.tokenSymbol}`
				return false
			}

			return true
		}

		const keyupValidator = debounce(validateBid, 50)

		const minimumBid = computed(() => {
			return currentBid.value.add(auctionStore.auction.bid_increment)
		})

		function updateMinimumBid() {
			state.bid = minimumBid.value.toDecimal()
		}

		async function handleBidClicked() {
			state.showErrorModal = false
			Bugsnag.leaveBreadcrumb('Clicked inline-bid button')

			const bidValid = validateBid()
			if (false == bidValid) {
				Bugsnag.leaveBreadcrumb('Bid invalid')
				// show generic error
				// console.error('invalid bid', state.error)
				state.showErrorModal = true
				return
			}

			Bugsnag.leaveBreadcrumb('Showing bid modal')
			state.showBidModal = true
		}

		function attachObservers() {
			clearObservers()
			observers.push(
				observe(auctionTitle.value, (scrollTop, ev) => {
					state.showStickyBanner = scrollTop <= 5
				})
			)

		}

		function clearObservers() {
			observers.splice(0, observers.length)
		}

		function observe(el, callback) {
			const listener = (ev) => {
				try {
					callback(el.offsetTop - document.documentElement.scrollTop, ev)
				} catch(e) {
					// skip
				}
			}

			document.addEventListener('scroll', listener)
			return listener
		}

		const currentBid = computed(() => {
			if (auctionStore.auction.high_bid) {
				return new Currency(auctionStore.auction.high_bid)
			}

			return new Currency(auctionStore.auction.starting_bid)
		})

		return {
			state,
			scrollHeader,
			// scrollBidBtn,
			walletStore,
			auctionTitle,
			customBid,
			auctionStore,
			appStore,
			Currency,
			currentBid,
			auctionOpen,
			keyupValidator,
			handleBidClicked,
			GeneralErrorComponent,
			minimumBid,
		}
	},
})
</script>
