<template>
	<router-link
		:to="{ name: 'auction', params: { auction: props.auction.id } }"
		class="block"
	>
		<div
			class="relative sm:grid ---grid-cols-[1fr,2fr] sm:grid-cols-[1fr,3fr] md:grid-cols-[1fr,2fr] lg:grid-cols-[1fr,4fr] bg-white rounded-lg m-2 shadow-lg"
		>
			<!-- border-slate-200 border-2"> -->

			<div
				class="rounded-lg rounded-r-none p-4 overflow-hidden bg-slate-50 h-full flex justify-center items-center"
			>
				<img
					class="rounded-md"
					v-if="images.length"
					:src="cdnURL(images[0].uri)"
					loading="lazy"
				/>
			</div>

			<div
				class="sm:pl-4 md:pl-6 pt-4 pb-4 sm:pb-2 sm:flex sm:flex-col h-full relative"
			>
				<button
					v-if="metamask.state.connected"
					class="group absolute top-4 right-4"
					@click.stop.prevent="toggleWatched(!props.watched)"
				>
					<span
						class="text-blue-200"
						:class="[
							props.watched ? 'hidden' : 'group-hover:hidden',
						]"
						><i class="fa-regular fa-heart"></i
					></span>
					<span
						class="group-hover:block"
						:class="[
							props.watched
								? 'group-hover:text-blue-200 block text-blue-700'
								: 'group-hover:block hidden text-sky-600',
						]"
						><i class="fa-solid fa-heart"></i
					></span>
				</button>

				<div class="mx-4 sm:mx-0">
					<bidder-flag
						v-if="props.auction.high_bidder"
						:auction="props.auction"
					/>

					<h2 class="text-lg font-semibold pr-9">
						{{ props.auction.title }}
					</h2>
					<p class="font-bold text-2xl">
						{{ $format(currentBid.toDecimal()) }}
						{{ appStore.tokenSymbol }}
					</p>
					<p class="text-xs text-slate-500 mt-.5">
						w/Buyers Premium:
						{{
							$format(
								currentBid
									.addPercentage(
										props.auction.buyer_premium_bps
									)
									.toDecimal()
							)
						}}
						{{ appStore.tokenSymbol }}
					</p>
				</div>

				<div class="flex-grow"></div>

				<div class="flex flex-col mt-2 space-y-1 mx-4 sm:mx-0">
					<p class="text-slate-500 text-sm">
						<i class="fa-fw fa-solid fa-gavel text-slate-400"></i>
						{{ props.auction.bids?.length }}
						<pluralize
							:amount="props.auction.bids?.length"
							singular="Bid"
							plural="Bids"
						/>
					</p>
					<time-remaining :auction="props.auction" />


					<div class="space-x-1">
						<i
							class="fa-fw fa-light fa-tag text-slate-400 fa-flip-horizontal"
						></i>
						<span
							v-for="tag in props.auction.tags.slice(0, 6)"
							class="text-xs inline-block px-2 py-.5 bg-slate-100 text-slate-500 rounded-lg"
						>
							{{ tag.name }}
						</span>
					</div>
				</div>
			</div>
		</div>
	</router-link>
</template>
<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { AuctionImage, Auction } from '@/types/Auction'
import { RouterLink } from 'vue-router'
import Currency from '@/types/Currency'
import { useAppStore } from '@/stores/AppStore'
import metamask from '@/util/metamask'
import { useWalletStore } from '@/stores/WalletStore'
import BidderFlag from './BidderFlag.vue'
import { cdnURL } from '@/util/cdn'
import TimeRemaining from './TimeRemaining.vue'

export default defineComponent({
	components: { BidderFlag, TimeRemaining },
	props: {
		auction: {
			type: Object as PropType<Auction>,
			required: true,
		},
		watched: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	emits: ['toggleWatched'],
	setup(props, { emit: $emit }) {
		const appStore = useAppStore()
		const walletStore = useWalletStore()

		const currentBid = computed(() => {
			return new Currency(
				props.auction.high_bid || props.auction.starting_bid,
				null
			)
		})

		const images = computed((): AuctionImage[] => {
			if (props.auction.images.length) {
				return props.auction.images
			}

			return [] as AuctionImage[]
		})

		function toggleWatched(watch: boolean) {
			$emit('toggleWatched', props.auction.id, watch)
		}

		return {
			props,
			appStore,
			walletStore,
			metamask,
			images,
			currentBid,
			Currency,
			toggleWatched,
			cdnURL,
		}
	},
})
</script>
