import { defineStore } from "pinia";
import api from '@/util/api'
import { AuthToken } from '@/stores/AuthStore'
import { Auction, Bid, StatusEnded, StatusExtended, StatusOpen } from '@/types/Auction'
import { useAuctionStore } from "./AuctionStore";
import metamask from "@/util/metamask";
import Bugsnag from "@bugsnag/js";
import { DateTime } from "ts-luxon";
import { useAuctionStatusStore } from "./AuctionStatusStore";

interface Profile {
	email: string
}

export const useProfileStore = defineStore('profile', {
	state: () => ({
		wallet: null as string | null,
		profile: {} as Profile,
		watched: [] as Auction[],
		bid: [] as Auction[],
		won: [] as Auction[],
		owned: [] as Auction[],
		bids: [] as Bid[],
	}),

	actions: {
		async loadAccount(token: AuthToken, force:boolean = false) {
			if ( force || ! this.wallet || this.wallet.toLowerCase() != token.wallet().toLowerCase()) {
				const profile = await api.loadProfile(token)

				this.profile = profile.profile
				this.bid = profile.bid_auctions || []
				this.won = profile.won_auctions || []
				this.owned = profile.owned_auctions || []
				// this.bids = profile.bids ?? []

				await this.loadWatched(token.wallet())

				this.wallet = token.wallet()
			}
		},

		clearProfile() {
			this.wallet = null
			this.profile = {}
			this.watched = []
			this.bid = []
			this.won = []
			this.owned = []
			this.bids = []
		},

		async watchAuction(token: AuthToken, auctionId: number, watch: boolean) : void {
			this.watched = await api.watchAuction(token, auctionId, watch)
		},

		async loadWatched(wallet: string, force:boolean = false ) : Promise<Auction[]> {
			if (this.wallet && this.wallet.toLowerCase() != wallet.toLowerCase()) {
				this.clearProfile()
			}

			if (force || ! this.wallet) {
				try {
					this.watched = await api.loadWatched(wallet)
				} catch (e) {
					Bugsnag.notify(e)
				}
			}

			return this.watched
		},

		recordTosAgreement(wallet: string, agree: boolean) {
			try {
				return window.localStorage.setItem(
					'tos-' + wallet.toLowerCase(),
					JSON.stringify(agree)
				)
			} catch (e) {
				Bugsnag.notify(e)
			}

			return false
		},

		hasAcceptedTos(wallet: string) {
			try {
				return JSON.parse(
					window.localStorage.getItem('tos-' + wallet.toLowerCase())
				)
			} catch (e) {
				Bugsnag.notify(e)
			}

			return false;
		}
	},

	getters: {
		ownedAuctions() : Auction[] {
			return this.owned;
		},

		watchedAuctions() : Auction[] {
			const auctionStore = useAuctionStore()
			return auctionStore.activeAuctions.filter(
				a => this.watched.indexOf(a.id) >= 0
					|| a.bids.filter(b => b.bidder.toLowerCase() == this.wallet?.toLowerCase()).length > 0
			)
		},

		watchlistBids() : { auction: Auction, bid: Bid }[] {
			const auctionStore = useAuctionStore()
			return auctionStore.activeAuctions.reduce(( bids, auction ) => {
				auction.bids.map(bid => {
					bids.push({
						auction,
						bid
					})
				}, [])

				return bids
			}, []) as { auction: Auction, bid: Bid }[]
		},

		winningAuctions() : Auction[] {
			const auctionStore = useAuctionStore()

			return auctionStore.activeAuctions.filter(
				(auction: Auction) => auction.high_bidder && auction.high_bidder.toLowerCase() == this.wallet?.toLowerCase()
			)
		},

		wonAuctions() : Auction[] {
			const auctionStore = useAuctionStore()
			return auctionStore.endedAuctions
				.filter(a => a.high_bidder && a.high_bidder.toLowerCase() == metamask.state.wallet?.toLowerCase())
		},

		claimableAuctions() : Auction[] {
			return this.wonAuctions.filter(
				a => a.claimed_at == null
			)
			.sort((a1, a2) => a1.end_at - a2.end_at)
		},

		soldAuctions() : Auction[] {
			return this.owned.filter(
				a => a.proceeds_claimed_at == null && (
					a.current_status == StatusEnded
					|| DateTime.now() > Math.max(
						a.auction_group.end_at.plus(a.extended_bidding_seconds),
						(a.last_bid_at ?? a.auction_group.end_at).plus(a.extended_bidding_seconds)
					)
				)
			)
			.sort((a1, a2) => (a1.last_bid_at ?? a1.auction_group.end_at) - (a2.last_bid_at ?? a2.auction_group.end_at))
			?? [] as Auction[]
		},

		allSoldAuctions() : Auction[] {
			const auctionStatusStore = useAuctionStatusStore()
			const auctionStore = useAuctionStore()
			return auctionStore.endedAuctions
				.filter(a => a.proceeds_claimed_at && a.proceeds_claimed_by.toLowerCase() == (this.wallet?.toLowerCase() ?? metamask.state.wallet.toLowerCase()))
				.sort((left, right) => {
					return right.proceeds_claimed_at - left.proceeds_claimed_at
				})
		},

		activeBids() : { auction: Auction, bid: Bid }[] {
			const auctionStatusStore = useAuctionStatusStore()
			return this.bids.filter(({ auction }) => auctionStatusStore.isOpen(auction))
		},

		bids() : { auction: Auction, bid: Bid }[] {
			const auctionStore = useAuctionStore()
			return auctionStore.auctions?.reduce(( bids: {auction: Auction, bid: Bid}[], auction : Auction) => {
				auction.bids
					.filter((b : Bid) => b.bidder.toLowerCase() == this.wallet?.toLowerCase())
					.map((bid : Bid) => {
						bids.push({
							auction,
							bid
						})
					})

				return bids
			}, []) as { auction: Auction, bid: Bid }[]
			?? [] as { auction: Auction, bid: Bid }[]
		},
	}
})
