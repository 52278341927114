<template>
	<div class="flex-shrink-0 whitespace-nowrap md:min-w-[20%] md:max-w-[30%]"
		:class="{'hidden': ! props.forceShow }">
		<h1 class="m-4 pb-1 border-b-2 border-slate-200 text-xl font-bold text-left flex justify-between items-center">
			Navigation
			<p class="md:hidden text-slate-400 font-normal text-base mx-1">
				<button @click="$emit('close')"><i class="text-lg text-red-500 fa-regular fa-circle-xmark"></i></button>
			</p>
		</h1>
		<div class="m-4 mt-0 rounded-xl border-2 border-slate-200 p-6 space-y-4 text-lg">
			<p>
				<router-link class="px-4 py-2 block w-full rounded-xl hover:font-bold"
					@click="$emit('close')"
					:to="{ name: 'account' }"
					active-class="text-slate-900 font-medium bg-slate-100">
					<i class="fa-fw fa-regular fa-house text-slate-500 mr-2"></i>
					Home
				</router-link>
			</p>
			<p>
				<router-link class="px-4 py-2 block w-full rounded-xl hover:font-bold"
					@click="$emit('close')"
					to="/account/watched"
					active-class="text-slate-900 font-medium bg-slate-100">
					<i class="fa-fw fa-regular fa-eye text-slate-500 mr-2"></i>
					Watchlist
				</router-link>
			</p>
			<p>
				<router-link class="px-4 py-2 block w-full rounded-xl hover:font-bold"
					@click="$emit('close')"
					to="/account/bids"
					active-class="text-slate-900 font-medium bg-slate-100">
					<i class="fa-fw fa-regular fa-gavel text-slate-500 mr-2"></i>
					Bids
				</router-link>
			</p>
			<p>
				<router-link class="px-4 py-2 block w-full rounded-xl hover:font-bold"
					@click="$emit('close')"
					to="/account/won"
					active-class="text-slate-900 font-medium bg-slate-100">
					<i class="fa-fw fa-regular fa-award text-slate-500 mr-2"></i>
					Won
				</router-link>
			</p>
			<p>
				<router-link class="px-4 py-2 block w-full rounded-xl hover:font-bold"
					@click="$emit('close')"
					to="/account/sold"
					active-class="text-slate-900 font-medium bg-slate-100">
					<i class="fa-fw fa-regular fa-cash-register text-slate-500 mr-2"></i>
					Sold
				</router-link>
			</p>
			<!-- <p>
				<router-link class="px-4 py-2 block w-full rounded-xl hover:font-bold"
					@click="$emit('close')"
					to="/account/settings"
					active-class="text-slate-900 font-medium bg-slate-100">
					<i class="fa-fw fa-regular fa-gears text-slate-500 mr-2"></i>
					Preferences
				</router-link>
			</p> -->
		</div>
	</div>
</template>
<script lang="ts">
import { useProfileStore } from "@/stores/ProfileStore";
import { DateTime } from "ts-luxon";
import { computed, defineComponent, PropType } from "vue";

export default defineComponent({
	props: {
		forceShow: {
			required: false,
			default: () => false,
			type: Boolean,
		}
	},
	setup(props) {
		const profileStore = useProfileStore()
		const soldAuctions = computed(() => {
			if ( ! profileStore.owned.length) {
				return 0
			}

			return profileStore.owned.filter((a) => {
				return a.proceeds_claimed_at === null &&
					DateTime.now() > a.auction_group.end_at.plus( a.extended_bidding_seconds )
			}).length;
		})

		const unclaimedWon = computed(() => {
			if ( ! profileStore.won.length) {
				return 0
			}

			return profileStore.won.filter((a) => {
				return a.claimed_at === null &&
					DateTime.now() > a.auction_group.end_at.plus( a.extended_bidding_seconds )
			}).length;
		})

		return {
			props,
			unclaimedWon,
			soldAuctions,
		}
	}
})
</script>
