<template>
	<Transition name="slide" type="animation">
			<outbid-notification
				v-if="(currentNotification && currentNotification.kind == NotificationType.Outbid)"
				:key="`notif-${counter}`"
				:notification="currentNotification"
				@close="close"
			/>
			<standard-notification
				v-else-if="currentNotification && currentNotification.kind == NotificationType.Standard"
				:key="`notif-${counter}`"
				:notification="currentNotification"
				@close="close"
			/>
	</Transition>
</template>
<script lang="ts">
import { useNotificationStore, NotificationType, Notification } from "@/stores/NotificationStore";
import { defineComponent, onMounted, onUnmounted, PropType, ref } from "vue";
import StandardNotification from "@/components/StandardNotification.vue";
import OutbidNotification from "@/components/OutbidNotification.vue";

export default defineComponent({
	components: { StandardNotification, OutbidNotification },
	setup(props) {
		const notificationStore = useNotificationStore()
		const currentNotification = ref<Notification | null>(null)
		let counter = ref(0)
		let watchInterval:number|null;

		onMounted(() => {
			watchForNotifications()
		})

		onUnmounted(() => {
			unWatchForNotifications()
		})

		function watchForNotifications() {
			if ( ! watchInterval) {
				watchInterval = setInterval(popAndShowNotification, 1000)
			}
			popAndShowNotification()
		}

		function unWatchForNotifications() {
			clearInterval(watchInterval)
			watchInterval = null
		}

		function popAndShowNotification() {
			if (notificationStore.toastNotifications.length == 0) {
				return;
			}

			unWatchForNotifications()
			const notif = notificationStore.toastNotifications.shift()
			currentNotification.value = notif
			counter.value++

			setTimeout(clearNotification, 3000)
		}

		function clearNotification() {
			currentNotification.value = null
			watchForNotifications()
		}

		function close() {
			clearNotification()
		}


		return {
			props,
			currentNotification,
			NotificationType,
			counter,
			close,
		}
	}
})
</script>
