<template>
	<modal>
		<div class="bg-slate-100 rounded-xl overflow-hidden px-6 py-6 mt-20 shadow-md">
			<!-- <img src="/image/bpx-horizontal.png" class="h-20"> -->
			<h1 class="border-b-2 border-sky-700 inline-block px-2 text-xl">Terms of Service</h1>
			<p class="border-l-2 border-slate-500 pl-6 ml-2 mt-6">
				I agree to the <a href="https://blokpax.com/terms-of-service" target="_blank" class="href-primary-dark">Terms of Service</a>, which includes the requirement that any
				dispute be resolved through binding arbitration. I also acknowledge that the Blokpax
				Collective <a href="https://bpxcollect.com/privacy" target="_blank" class="href-primary-dark">Privacy Notice</a> was made available to me.
			</p>

			<div class="text-center mt-6">
				<p><button class="inline-block btn-primary-lg px-12"
					@click="agree">I agree</button></p>
				<p class="mt-2">
					<button class="primary-href-dark underline"
						@click="disagree">I do not agree</button>
				</p>
			</div>
		</div>
	</modal>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
	commits: ['agree', 'disagree'],
	setup(props, { emit: $emit }) {
		function agree() {
			$emit('agree')
		}

		function disagree() {
			$emit('disagree')
		}

		return {
			agree,
			disagree
		}
	}
})
</script>
