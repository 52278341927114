import { DateTime } from 'ts-luxon'
import { Auction, StatusEnded, StatusExtended, StatusOpen } from './Auction'
import { useProfileStore } from '@/stores/ProfileStore'

export interface AuctionFilter {
	id: string
	name: string
	passes: (p: AuctionFilterPredicate) => boolean
}

export interface AuctionFilterPredicate {
	auction: Auction
	user: string | null
}

export const AVAILABLE_FILTERS = [
	{
		id: 'OPEN',
		name: 'Open',
		passes: ({ auction, user }: AuctionFilterPredicate) => {
			return auction.current_status == StatusOpen
				&& DateTime.now() < Math.max(
					auction.auction_group.end_at,
					auction.last_bid_at
						? auction.last_bid_at.plus(auction.extended_bidding_seconds)
						: auction.auction_group.end_at.plus(auction.extended_bidding_seconds)
				)
		},
	},
	{
		id: 'EXTENDED',
		name: 'In Extended Bidding',
		passes: ({ auction, user }: AuctionFilterPredicate) => {
			return auction.current_status == StatusExtended
				|| (
					DateTime.now() > auction.auction_group.end_at
					&& (
						(auction.last_bid_at && DateTime.now() < auction.last_bid_at.plus(auction.extended_bidding_seconds))
						|| DateTime.now() < auction.auction_group.end_at.plus(auction.extended_bidding_seconds)
					)
				)
		},
	},
	{
		id: 'ENDED',
		name: 'Concluded',
		passes: ({ auction, user }: AuctionFilterPredicate) => {
			return auction.current_status == StatusEnded
				|| (
					DateTime.now() > auction.auction_group.end_at.plus(auction.extended_bidding_seconds)
					&& (
						! auction.last_bid_at
						|| DateTime.now() > auction.last_bid_at.plus(auction.extended_bidding_seconds)
					)
				)
		},
	},
	{
		id: 'IS_BIDDER',
		name: "Auctions I've Bid On",
		passes: ({ auction, user }: AuctionFilterPredicate) => {
			return (
				user !== null &&
				auction.bids.filter(
					(b) => b.bidder.toLowerCase() == user.toLowerCase()
				).length > 0
			)
		},
	},
	{
		id: 'IS_HIGH_BIDDER',
		name: "Auctions I'm Winning",
		passes: ({ auction, user }: AuctionFilterPredicate) => {
			return (
				user !== null &&
				auction.high_bidder !== null &&
				auction.high_bidder.toLowerCase() == user.toLowerCase()
			)
		},
	},
	{
		id: 'WATCHING',
		name: "Auctions I'm Watching",
		passes: ({ auction, user }: AuctionFilterPredicate) => {
			const profileStore = useProfileStore()
			return profileStore.watched.length && profileStore.watched.indexOf(auction.id) >= 0
		}
	}
] as AuctionFilter[]
