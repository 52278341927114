<template>
	<div class="bg-slate-300 flex justify-between items-stretch px-2 drop-shadow-md font-poppins">
		<div class="flex items-center">
			<div class="rounded-xfull border-transparent border-2 overflow-hidden flex items-center justify-center">
				<!-- <img src="/image/bpx-logo@2000.png" class="w-14"> -->
				<img src="/image/bpx-horizontal.png" class="h-8">
			</div>
			<router-link :to="{ name: 'auction-index' }" class="text-xl block py-6 px-4 border-b-2 border-transparent hover:border-slate-400">
				Auctions
			</router-link>
			<!-- <router-link to="/faq" class="block py-6 px-4 border-b-2 border-transparent hover:border-slate-400">
				FAQ
			</router-link> -->
		</div>
		<wallet-connected>
			<template #default>
				<div class="flex justify-center items-center pr-4">
					<button class="btn-primary"
						@click.stop="metamask.connect">
						Connect Wallet
					</button>
				</div>
			</template>
			<template #connected="wallet">
				<router-link :to="{ name: 'account' }" class="flex flex-col justify-center items-center pl-4 border-b-2 border-transparent hover:border-slate-400">
					<div class="text-center flex-grow flex justify-between items-center">
						<p class="flex-auto font-medium leading-none">{{ wallet.wallet.substr(0, 8) }}</p>
						<div class="rounded-full border-2 border-slate-400 w-[30px] h-[30px] ml-2">
							<jazzicon
								:address="metamask.state.wallet"
								:seed="7"
								:diameter="26"
								class="w-full"
							/>
						</div>
					</div>
					<p class="text-xs text-slate-500 flex-auto">
						<i class="fa-solid fa-wallet"></i>
						{{ $format(currentBalance.toDecimal()) }}
						{{ appStore.tokenSymbol }}
					</p>
				</router-link>
			</template>
		</wallet-connected>
	</div>
</template>
<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import WalletConnected from "./WalletConnected.vue";
import metamask from '@/util/metamask'
import { useWalletStore } from "@/stores/WalletStore";
import { useAppStore } from "@/stores/AppStore";

export default defineComponent({
    components: { WalletConnected },
	setup(props) {
		const walletStore = useWalletStore()
		const appStore = useAppStore()

		const currentBalance = computed(() => {
			return walletStore.currentBalance;
		})

		return {
			metamask,
			appStore,
			currentBalance
		}
	}
})
</script>
