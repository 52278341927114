<template>
	<div class="mt-3">
		<h3 class="font-semibold">{{ props.category.name }}</h3>
		<ul class="ml-2 space-y-3 mt-2">
			<li
				v-for="tag in props.category.tags"
				@click.stop="toggleFilter(tag)"
				class="cursor-pointer"
			>
				<div
					v-if="tagMap.has(tag.id)"
					class="flex space-x-2 items-center"
				>
					<div
						class="flex items-center justify-center w-8 flex-grow-0 flex-shrink-0 aspect-square border-2 rounded-lg"
						:class="
							taxonomyStore.editingFilters[tag.id]
								? [
										'bg-sky-100',
										'text-sky-600',
										'border-sky-600',
								  ]
								: ['border-slate-300']
						"
					>
						<span v-show="taxonomyStore.editingFilters[tag.id]"
							><i class="fa fa-check"></i
						></span>
					</div>
					<div class="flex-grow flex justify-between items-center">
						<p>{{ tag.name }}</p>
						<p class="text-slate-500 font-light">
							{{
								tagMap.has(tag.id)
									? tagMap.get(tag.id).length
									: 0
							}}
						</p>
					</div>
				</div>
			</li>
		</ul>
	</div>
</template>
<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { Tag, Taxonomy } from '@/types/Taxonomy'
import { useTaxonomyStore } from '@/stores/TaxonomyStore'
import { useAuctionStore } from '@/stores/AuctionStore'
import { AuctionGroup } from '@/types/Auction'

export default defineComponent({
	props: {
		group: {
			type: Object as PropType<AuctionGroup>,
			required: true,
		},
		category: {
			type: Object as PropType<Taxonomy>,
			required: true,
		},
	},
	setup(props) {
		const taxonomyStore = useTaxonomyStore()
		const auctionStore = useAuctionStore()

		const tagMap = computed(() => {
			return auctionStore.tagMapByGroup(props.group.id)
		})

		function toggleFilter(tag: Tag) {
			taxonomyStore.toggleModifiedFilter(tag)
		}

		return {
			props,
			taxonomyStore,
			auctionStore,
			toggleFilter,
			tagMap,
		}
	},
})
</script>
