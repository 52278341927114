<template>
	<div>
		<h1 class="border-b-2 border-slate-200 pb-1 flex items-end space-x-2 justify-between">
			<p class="text-xl font-bold">Watchlist</p>
			<div class="flex justify-end items-center space-x-2 mb-2">
				<div class="font-poppins inline-block mr-2 text-lg">
					Sort
				</div>
				<select
					class="rounded-md shadow-md border-slate-300"
					v-model="state.sort"
				>
					<option v-for="sort in Sorts" :key="`sort-${sort}`"
						:value="sort">
						{{ SortLabels[ sort ] }}
					</option>
				</select>
			</div>
		</h1>

		<div class="space-y-8 mt-4">
			<watchlist-item
				v-if="profileStore.watchedAuctions.length"
				v-for="auction in sortedWatchlist"
				:key="`watchlist-auction-${auction.id}`"

				:auction="auction"
			/>
			<p v-else-if="profileStore.watchedAuctions.length"
				class="text-center text-slate-500 text-lg my-4">
				The active auctions you're watching do not have any activity
			</p>
			<p v-else
				class="text-center text-slate-500 text-lg my-4">
				You're not watching any active auctions
			</p>
		</div>
	</div>
</template>
<script lang="ts">
import { computed, defineComponent, PropType, onMounted, reactive } from "vue";
import AuctionList from "@/pages/Account/AuctionList.vue";
import AuctionListingCondensed from "@/components/AuctionListingCondensed.vue";
import WatchlistItem from "@/components/WatchlistItem.vue";
import { useAuctionStore } from "@/stores/AuctionStore";
import { useProfileStore } from "@/stores/ProfileStore";
import { useAuthStore } from "@/stores/AuthStore";
import { useAppStore } from "@/stores/AppStore";
import { Sorts, SortLabels, useAuctionSort } from "@/util/AuctionSort";
import debounceRef from '@/util/debounceRef'

export default defineComponent({
	components: { AuctionList, AuctionListingCondensed, WatchlistItem },
	setup(props) {
		const auctionStore = useAuctionStore()
		const profileStore = useProfileStore()
		const authStore = useAuthStore()
		const appStore = useAppStore()
		const getSortFunction = useAuctionSort()

		const state = reactive({
			sort: Sorts.BID_ACTIVITY_DESC,
		})

		onMounted(async() => {
			const t = await authStore.authToken()
			return profileStore.loadAccount(
				t
			);
		})

		const sortedWatchlist = computed(() => {
			return profileStore.watchedAuctions
				.sort(
					getSortFunction(state.sort)
				)
		})

		const auctions = profileStore.watchedAuctions

		return {
			state,
			auctions,
			Sorts,
			SortLabels,
			profileStore,
			sortedWatchlist
		}
	}
})
</script>
