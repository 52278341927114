<template>
	<p class="text-slate-500 text-sm">
		<i class="fa-fw fa-regular fa-clock text-slate-400"></i>
		{{ timeRemaining() }}
	</p>
</template>
<script lang="ts">
import { Auction } from "@/types/Auction";
import { defineComponent, onUnmounted, PropType } from "vue";
import { countdown, Target } from '@/util/countdownAuction'
import { useAuctionStatusStore } from "@/stores/AuctionStatusStore";
import { makeLabel } from '@/util/timeRemainingLabel'

export default defineComponent({
	props: {
		auction: {
			required: true,
			type: Object as PropType<Auction>,
		}
	},
	setup(props) {
		const { duration, countState, destruct } = countdown(
			props.auction, useAuctionStatusStore()
		)

		onUnmounted(() => {
			destruct()
		})

		function timeRemaining() {
			const { val, label } = makeLabel(duration.value)

			if (countState.value == Target.CountFromEnd) {
				return `Ended ${val} ${label} ago`
			} else if (countState.value == Target.CountToStart) {
				return `Begins in ${val} ${label}`
			} else if (countState.value == Target.CountToExtended) {
				return `${val} ${label} until extended bidding`
			} else {
				return `Ends in ${val} ${label}`
			}
		}

		return {
			props,
			timeRemaining,
		}
	}
})
</script>
