<template>
	<div class="mx-4">
		<slot name="header">
			<div class="border-b-2 border-slate-300 mt-4">
				<h2 class="text-xl">{{ props.title || "Section Title" }}</h2>
			</div>
		</slot>

		<div v-if="0 == props.auctions.length">
			<p class="text-gray-400">No auctions to show</p>
		</div>
		<div v-else class="mt-2">
			<div v-for="auction in props.auctions">
				<slot name="auction-item" :auction="auction">
					<auction-listing-condensed :auction="auction" class="flex-1" />
				</slot>
			</div>
		</div>
	</div>
</template>
<script lang="ts">
import { defineComponent, PropType, onMounted } from "vue";
import AuctionListingCondensed from "@/components/AuctionListingCondensed.vue";
import { useAppStore } from "@/stores/AppStore";

export default defineComponent({
	components: { AuctionListingCondensed },
	props: {
		title: {
			required: false
		},
		auctions: {
			required: true,
		}
	},
	setup(props) {
		const appStore = useAppStore()
		onMounted(async() => {
			await props.auctions
		});

		return {
			props,
			appStore
		}
	}
})
</script>
