<template>
	<div class="flex flex-col">
		<div class="mb-2 flex-grow">
			<p class="text-lg font-semibold md:flex justify-start items-start space-y-2 md:space-y-0 md:space-x-4 flex-grow">
				<span class="flex-grow">
					{{ auction.title }}
					<br>
					<span class="flex space-x-1 text-xs font-normal">
						<time-remaining :auction="auction" />
						<span class="text-sm text-slate-500">
							<i class="fa-fw fa-solid fa-gavel"></i>
							{{ $format(auction.bids.length) }}
							<pluralize
								:amount="auction.bids.length"
								singular="Bid"
								plural="Bids"
							/>
						</span>
					</span>
				</span>
				<slot name="buttons">
					<router-link
						custom
						:to="{ name: 'auction', params: { auction: auction.id, group: auction.auction_group.slug }}"
						v-slot="{ navigate }">
						<button role="link" @click="navigate"
							class="font-base btn-primary flex-shrink-0 whitespace-nowrap">
							View
							<i class="fa-regular fa-eye"></i>
						</button>
					</router-link>
				</slot>
			</p>
		</div>
		<div class="flex space-x-4 mt-2">
			<div class="max-w-[6rem]">
				<img :src="$cdn(auction.images[0].uri)" class="border-2 border-slate-300 rounded-lg overflow-hidden shadow-md shadow-gray-500" />
			</div>
			<slot name="default">
				<div class="flex-grow flex flex-col">
					<p class="text-xl font-semibold">
						{{ $format(highBid.toDecimal()) }}
						{{ appStore.tokenSymbol }}
					</p>
					<p class="text-sm text-slate-500 text-italic" v-if="props.showBuyerPremium">
						{{ $format(highBid.addPercentage(props.auction.buyer_premium_bps).toDecimal()) }}
						{{ appStore.tokenSymbol }}
						w/Buyer's Premium
					</p>
				</div>
			</slot>
		</div>
	</div>
<!--
	<div class="">
		<div class=" pb-2 mb-2 border-b-2 border-slate-200">
			<p class="text-lg font-semibold flex justify-start items-center space-x-4">
				<span class="flex-grow">
					{{ auction.title }}
				</span>
				<router-link
					custom
					:to="{ name: 'auction', params: { auction: auction.id, group: auction.auction_group.slug }}"
					v-slot="{ navigate }">
					<button role="link" @click="navigate"
						class="font-base btn-primary flex-shrink-0 whitespace-nowrap">
						View
						<i class="fa-regular fa-eye"></i>
					</button>
				</router-link>
			</p>
			<p class="flex space-x-2 text-slate-500">
				<time-remaining :auction="auction" />
				<span class="text-sm">
					<i class="fa-fw fa-solid fa-gavel"></i>
					{{ $format(auction.bids.length) }}
					<pluralize
						:amount="auction.bids.length"
						singular="Bid"
						plural="Bids"
					/>
			</span>
			</p>
		</div>
		<div class="flex space-x-4 mt-2">
			<div class="max-w-[6rem]">
				<img :src="$cdn(auction.images[0].uri)" class="border-2 border-slate-300 rounded-lg overflow-hidden shadow-md shadow-gray-500" />
			</div>
				<div class="flex-grow">
					<span class="font-medium">{{ props.auction.title }}</span>
				</div>
				<div class="">
					<p class="text-xl font-semibold">
						{{ $format(highBid.toDecimal()) }}
						{{ appStore.tokenSymbol }}
					</p>
					<p class="text-sm text-slate-500 text-italic" v-if="props.showBuyerPremium">
						{{ $format(highBid.addPercentage(props.auction.buyer_premium_bps).toDecimal()) }}
						{{ appStore.tokenSymbol }}
						w/Buyer's Premium
					</p>
				</div>
			</div>
			<time-remaining class="mt-2" :auction="props.auction" />
			<slot name="actions"></slot>
		</div>
	</div> -->
</template>
<script lang="ts">
import { useAppStore } from "@/stores/AppStore";
import { defineComponent, PropType, computed } from "vue";
import Currency from "@/types/Currency";
import { countdown } from '@/util/countdownAuction'
import { Auction } from '@/types/Auction'
import TimeRemaining from "./TimeRemaining.vue";
import { useAuctionStatusStore } from "@/stores/AuctionStatusStore";

export default defineComponent({
	components: { TimeRemaining },
	props: {
		auction: {
			type: Object as PropType<Auction>,
			required: true,
		},
		"showBuyerPremium": {
			type: Boolean,
			required: false,
			default: () => true,
		}
	},
	setup(props) {
		const appStore = useAppStore()
		const {
			duration,
			countState,
			Target,
		} = countdown(props.auction, useAuctionStatusStore())

		const highBid = computed(() => {
			if (props.auction.high_bid) {
				return new Currency(props.auction.high_bid);
			}

			return new Currency(props.auction.starting_bid);
		})

		const auction = computed(() => {
			return props.auction
		})

		const timeRemaining = computed(() => {
			const makeLabel = function (): any {
				for (const unit of ['Days', 'Hours', 'Minutes', 'Seconds']) {
					if (duration[unit.toLowerCase()] > 0 || unit == 'Seconds') {
						const val = duration[unit.toLowerCase()]
						let label = unit

						if (val == 1) {
							label = label.substring(0, label.length - 1)
						}

						return { val, label: label.toLowerCase() }
					}
				}
			}

			const { val, label } = makeLabel()

			if (countState.value == Target.CountFromEnd) {
				return `Ended ${val} ${label} ago`
			} else if (countState.value == Target.CountToStart) {
				return `Begins in ${val} ${label}`
			} else {
				// if (countState.value == Target.CountToEnd) {
				return `Ends in ${val} ${label}`
				// } else {
				// return `${val} ${label} until extended`
			}
		})

		return {
			appStore,
			props,
			Currency,
			auction,
			highBid,
			timeRemaining
		}
	}
})
</script>
