<template>
	<div class="flex flex-col min-h-screen">
		<div
			v-if="walletStore.currentChain != CHAIN && metamask.state.connected"
			class="text-sm md:text-lg bg-orange-400 text-slate-900 text-center py-2 px-6 border-b-2 border-orange-500"
		>
			The BPX Auction is only available on the Ethereum mainnet.
			<button
				class="text-blue-700 underline"
				@click.stop="selectNetwork(CHAIN)"
			>
				Change networks
			</button>
			to continue.
		</div>

		<main-navigation class="z-30" />
		<router-view class="pb-12 min-h-screen" :key="route.fullPath.replace(/(#.*)$/, '')" />
		<div class="flex-auto"></div>
		<app-footer />
		<transition>
			<TosModal
				v-if="showTosModal"
				@agree="recordAgreementContinue"
				@disagree="recordDisagreement"
			/>
		</transition>
		<ToastNotifier />
		<DemoNotificationButtons v-if="local" />
	</div>
</template>
<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import { useAuctionStore } from '@/stores/AuctionStore'
import { useNotificationStore } from '@/stores/NotificationStore'
import { useProfileStore } from '@/stores/ProfileStore'
import { useWalletStore, BPX_ADDRESS } from '@/stores/WalletStore'
import metamask from '@/util/metamask'
import TosModal from '@/components/TosModal.vue'
import ToastNotifier from '@/components/ToastNotifier.vue'
import DemoNotificationButtons from '@/components/DemoNotificationButtons.vue'
import { ref } from 'vue'
import Bugsnag from '@bugsnag/js'
let local;
try {
	local = import.meta.env.VITE_ENV == 'local'
} catch (e) {
	local = false
}

const route = useRoute()
const router = useRouter()
const auctionStore = useAuctionStore()
auctionStore.fetchAuctions()

const notificationStore = useNotificationStore();

const CHAIN = import.meta.env.VITE_CHAIN
const walletStore = useWalletStore()
walletStore.monitorCurrency(BPX_ADDRESS, CHAIN)
walletStore.init(false)

const profileStore = useProfileStore()

window.Pusher = Pusher
const echo = new Echo({
	broadcaster: 'pusher',
	key: import.meta.env.VITE_PUSHER_APP_KEY,
	cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
	forceTLS: true,
})

const selectNetwork = async (id: number): Promise => {
	// console.log(walletStore.currentChain)
	await metamask.requestChain(id)
}

echo.channel('bid-received').listen('BidReceived', (bid) => {
	// console.log('new bid received', bid)
	Bugsnag.leaveBreadcrumb(
		'Received bid',
		{
			auction: bid.auction_id,
		},
		'log'
	)
	auctionStore.addBid(bid)
})

echo.channel('bid-received').listen('NewHighBid', async({ auction, lastBid }) => {
	Bugsnag.leaveBreadcrumb(
		'Received high bid',
		{
			auction: auction.blockchain_auction_id,
			title: auction.title,
		},
		'log'
	)

	auctionStore.recordHighBid(auction)

	let auctions = await auctionStore.fetchAuctions();
	let auctionListing = auctions.filter(a => a.id == auction.id)

	if ( auctionListing.length == 0 ) {
		Bugsnag.notify(new Error(`received NewHighBid for auction not in auction listing (auction: ${auction.id})`));
		return
	}

	notificationStore.dispatchBidNotification(
		auctionListing[0],
		lastBid
	);
})

const showTosModal = ref(false)
function recordAgreementContinue() {
	Bugsnag.leaveBreadcrumb(
		'Agreed to TOS',
		{
			wallet: walletStore.wallet,
		},
		'log'
	)
	profileStore.recordTosAgreement(walletStore.wallet, true)
	showTosModal.value = false
	loadWatchedAuctions(walletStore.wallet)
}

function recordDisagreement() {
	Bugsnag.leaveBreadcrumb(
		'Did NOT agree to TOS',
		{
			wallet: walletStore.wallet,
		},
		'log'
	)
	profileStore.recordTosAgreement(walletStore.wallet, false)
	router.push('/tos-exit')
	showTosModal.value = false
}

function checkTos(wallet: string) {
	if (wallet.length) {
		showTosModal.value = !profileStore.hasAcceptedTos(wallet)
		return true;
	}

	showTosModal.value = false
	return false;
}

function loadWatchedAuctions(wallet:string) {
	if ( ! wallet || 0 == wallet.length) {
		return
	}

	profileStore.loadWatched(wallet)
}

metamask.on('accountChanged', (wallet) => {
	Bugsnag.leaveBreadcrumb(
		'Changed wallet',
		{
			wallet,
		},
		'log'
	)

	if (checkTos(wallet)) {
		loadWatchedAuctions(wallet)
	}
})

metamask.on('connected', ({ wallet }) => {
	Bugsnag.leaveBreadcrumb(
		'connected wallet',
		{
			wallet,
		},
		'log'
	)

	if (checkTos(wallet)) {
		console.log(wallet)
		loadWatchedAuctions(wallet)
	}
})

metamask.on('disconnected', () => {
	Bugsnag.leaveBreadcrumb('disconnected wallet')
	showTosModal.value = false
})
</script>
