import { Auction, StatusClosed, StatusEnded, StatusExtended, StatusOpen } from '@/types/Auction'
import { DateTime } from 'ts-luxon'
import { ref, computed } from 'vue'
import { getBreakdown } from '@/util/countdown'

export const Target = {
	CountToStart: Symbol('start'),
	CountToExtended: Symbol('extend'),
	CountToEnd: Symbol('end'),
	CountFromEnd: Symbol('ended')
}

const timers = new Map<number, any>()
const intervals = new Map<number, { wait: number, intvl: number }>()
const refCounter = new Map<number, number>()

export function countdown(auction: Auction, auctionStatusStore) {
	if ( ! timers.get( auction.id ) || ! intervals.get(auction.id)) {
		const countState = computed(() => {
			switch (auctionStatusStore.status( auction.id )) {
				case StatusClosed:
					return Target.CountToStart

				case StatusOpen:
					if (auction.extended_bidding_seconds
						&& auction.extended_bidding_seconds.seconds > 0) {
						return Target.CountToExtended
					}

					return Target.CountToEnd

				case StatusExtended:
					return Target.CountToEnd

				case StatusEnded:
					return Target.CountFromEnd
			}
		})
		const duration = ref(getBreakdown(getTs().diff(DateTime.now()).milliseconds))
		const finished = ref(isFinished(DateTime.now().toSeconds()))

		function getTs() : DateTime {
			switch (countState.value) {
				case Target.CountToStart:
					return auction.auction_group.start_at

				case Target.CountToExtended:
					return auction.auction_group.end_at

				default:
				case Target.CountToEnd:
					if (auction.end_at) {
						return auction.end_at
					}

					return auction.last_bid_at.plus( auction.extended_bidding_seconds )
			}
		}

		function isFinished(now: number) : boolean {
			return now > Math.floor(getTs().toSeconds())
		}

		function updateDuration() {
			// console.log('updating duration', auction.id)
			const now = DateTime.now()
			const diff = getTs().diff(now)

			duration.value = getBreakdown(diff.milliseconds)
			finished.value = isFinished(now.toSeconds())

			const interval = intervals.get(auction.id).wait
			if (diff.milliseconds < 0 && Math.abs(diff.milliseconds) >= 60 * 1000) {
				if (interval < 60 * 1000) {
					stopInterval()
					startInterval( 60 * 1000)
				}
			} else if (diff.milliseconds < 0 && Math.abs(diff.milliseconds) >= 60 * 60 * 1000) {
				if (interval < 60 * 60 * 1000) {
					stopInterval()
					startInterval( 60 * 60 * 1000 )
				}
			}
		}

		function destruct() {
			if (refCounter.get(auction.id) < 1) {
				stopInterval()
				refCounter.delete(auction.id)
			}
		}

		function stopInterval() {
			window.clearInterval(intervals.get(auction.id).intvl)
			intervals.delete(auction.id)
		}

		function startInterval(wait: number) {
			intervals.set(auction.id, {
				wait,
				intvl: setInterval(() => {
					updateDuration()
				}, wait)
			})
		}

		startInterval( 200 )

		timers.set(auction.id, {
			duration,
			finished,
			countState,
			destruct
		})
	}

	refCounter.set(auction.id, (refCounter.get(auction.id) ?? 0) + 1)
	return timers.get(auction.id)
}




	// function getCountState() {
	// 	const now = DateTime.now()

	// 	if (now < auction.auction_group.start_at) {
	// 		return countState.value = Target.CountToStart
	// 	}

	// 	if (now < auction.auction_group.end_at) {
	// 		return countState.value = Target.CountToExtended
	// 	}

	// 	if (now < auction.end_at) {
	// 		return countState.value = Target.CountToEnd
	// 	}

	// 	// if ((auction.last_bid_at && now < auction.last_bid_at.plus( auction.extended_bidding_seconds ))
	// 	// || (! auction.last_bid_at && now < auction.auction_group.end_at.plus( auction.extended_bidding_seconds))) {
	// 		return countState.value = Target.CountToEnd
	// 	// getCountState}

	// 	// return Target.CountFromEnd
	// }



// 	function updateCountdown() {
// 		const now = DateTime.now()
// 		// console.log('updating countdown', 'now', now, 'timestamp', timestamp.value)
// 		if (now > timestamp.value) {
// 			duration.value = intervalToDuration({
// 				start: timestamp.value.toJSDate(),
// 				end: now.toJSDate(),
// 			})

// 			return;
// 		}

// 		duration.value = intervalToDuration({
// 			start: now.toJSDate(),
// 			end: timestamp.value.toJSDate(),
// 		})
// 	}

// 	function _clearInterval() {
// 		window.clearInterval(interval)
// 	}

// 	function destruct() {
// 		_clearInterval()
// 	}

// 	interval = setInterval(updateCountdown, 450)
// 	updateCountdown()

// 	return {
// 		countState,
// 		timestamp,
// 		// finished,
// 		duration,
// 		Target,
// 		destruct,
// 	}
// }
