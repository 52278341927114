<template>
	<p>{{ props.error || 'An uknown error occurred' }}</p>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
	props: {
		error: {
			type: null as PropType<String | null>,
			required: false,
			default: () => new Error()
		}
	},
	setup(props) {

		return {
			props
		}
	}
})
</script>
